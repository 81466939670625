import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/auth";
import { Route, Link, Switch, useLocation } from "react-router-dom";
import * as api from "../api/_index";
import { useSelector, useDispatch } from "react-redux";
import {
  logIn,
  logOut,
  updateStarMeUser,
  getFirebaseUser,
} from "../features/user/userSlice";
import { GoStar } from "react-icons/go";
import { textContents } from "../assets/textContents";
import { FaFacebook } from "react-icons/fa";
import { checkLoginState } from "../services/FacebookSdk";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

export function ErrorPage(props) {
  const textJson = textContents.introPage;
  const dispatch = useDispatch();

  return (
    <Container>
      <h1>{props.code}</h1>
      <h2>not found</h2>
      <Link to="/">
        <button>go to main</button>
      </Link>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin: 0 auto;
`;

const YellowStar = styled(GoStar)`
  margin: 0;
  position: absolute;
  z-index: -1;
`;
const BlackStar = styled.svg`
  margin: 10px auto 0;
`;

const Title = styled.h1`
  font-size: ${0.1 * width}px;
  margin: 0;
`;

const SubTitle = styled.h2`
  white-space: pre-line;
  margin: 10px;
  font-size: ${0.04 * width}px;
`;

const FacebookLoginButton = styled.button`
  width: 60%;
  height: 8%;
  border-radius: 10px;
  background-color: #1877f2;
  color: white;
  font-size: ${0.04 * width}px;
  border-width: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const FacebookText = styled.p`
  margin: auto 10px;
`;

//production
var facebookAppId = 522494749141184;
var clientAccessToken = "522494749141184|593df5ccd551f0df1613e2d0d356971d";
var _firebaseConfig = {
  apiKey: "AIzaSyAlS3dOtGT1o4t4KxfCIiOdaq4rJ-n5F-E",
  authDomain: "facebookauth.starme.site",
  projectId: "star-me-40e56",
  storageBucket: "star-me-40e56.appspot.com",
  messagingSenderId: "1006375097805",
  appId: "1:1006375097805:web:00366e1f1971a6b0cc2084",
  measurementId: "G-NPCRKN6M4V",
};

if (process.env.NODE_ENV === "development") {
  console.log("this is dev mode");
  // //test
  facebookAppId = 4340815215931877;
  clientAccessToken = "4340815215931877|3e3b51a544a8699fd53ca65d1f084a7e";
  _firebaseConfig = {
    apiKey: "AIzaSyAzWKudmh1F96vjSA-kh6O1Ib88Ju8MVAU",
    authDomain: "star-me-test.firebaseapp.com",
    projectId: "star-me-test",
    storageBucket: "star-me-test.appspot.com",
    messagingSenderId: "678551850348",
    appId: "1:678551850348:web:45f9c3459508a4f7f0b749",
    measurementId: "G-8LDPNLXKV6",
  };
}

export const facebookConstant = {
  facebookAppId: facebookAppId,
  clientAccessToken: clientAccessToken,
};

export const firebaseConfig = _firebaseConfig;

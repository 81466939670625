import getServerAddress from "../api/getServerAddress";
import KakaoButton from "../components/KakaoButton";
import * as api from "../api/_index";

function checkKakaoInitialized() {
  const Kakao = window.Kakao;
  if (!Kakao.isInitialized()) {
    // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
    Kakao.init("e009488e46ca82af972d7f1aea0a28e2");
  }
  return Kakao;
}
export function kakaoSetToken(token) {
  const Kakao = checkKakaoInitialized();
  Kakao.Auth.setAccessToken(token);
}

export function kakaoLogin(state) {
  const Kakao = checkKakaoInitialized();
  const redirectUri = getServerAddress() + "/api/v1/user/kakao/";
  const scope = "account_email,profile,friends";
  Kakao.Auth.authorize({
    redirectUri: redirectUri,
    state: JSON.stringify(state),
    scope: scope,
  });
}

export function getKakaoFriends() {
  return new Promise((resolve, reject) => {
    const Kakao = checkKakaoInitialized();

    Kakao.API.request({
      url: "/v1/api/talk/friends",
      success: function (response) {
        resolve(response);
      },
      fail: function (error) {
        console.log(error);
        reject(error);
      },
    });
  });
}

export function setKakaoFriendsAsStarmeFriends(uid_list, idToken) {
  const userAPI = new api.UserAPI(idToken);
  return new Promise((resolve, reject) => {
    userAPI
      .set_friends([], uid_list)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("error in App.js");
        console.log(error);
      });
  });
}

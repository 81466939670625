import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import * as api from "../api/_index";
import {
  getFirebaseUser,
  getStarMeUser,
  logIn,
  logOut,
  selectUser,
  getIdToken,
} from "../features/user/userSlice";
import { textContents } from "../assets/textContents";
import StarRatings from "react-star-ratings";

import { trackPromise } from "react-promise-tracker";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import StyledStarRatings from "../components/StyledStarRatings";

import {
  Container,
  Spacer,
  StyledButton,
  StyledLink,
} from "../components/styled";
import colors from "../assets/colors.json";
import { getFBImgUrl } from "../services/FacebookSdk";
import { RiStarSFill } from "react-icons/ri";

import { LogScreen } from "../services/analytics";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

export function MyStarDetailPage(props) {
  const logScreen = new LogScreen("MyStarDetailPage");

  useEffect(() => {
    logScreen.screenLog();
  }, []);

  const textJson = textContents.myStarDetailPage;
  const firebaseUser = useSelector(getFirebaseUser);
  const starMeUser = useSelector(getStarMeUser);
  const idToken = useSelector(getIdToken);
  const dispatch = useDispatch();
  const alert = useAlert();

  const starset = starMeUser ? starMeUser.profile.starsets[0] : null;
  const encodedId = starset.encoded_id;
  const enoughNum = 5;
  const isEnough = starset.num_of_valid_stars >= enoughNum;
  const facebookAccessToken = useSelector(
    (state) => state.user.facebookAccessToken
  );

  const [starsetData, setStarsetData] = useState([]);
  const starsetItems = starsetData.map((starset, index) => (
    <li key={index}>
      <StarSet data={starset} />
    </li>
  ));

  function StarSet(props) {
    const data = props.data;
    const title = data.title;
    const starListData = data.stars;
    const starItems = starListData
      .sort((a, b) => {
        if (a.value > b.value) {
          return -1;
        } else if (a.value < b.value) {
          return 1;
        } else {
          if (a.comment) {
            return -1;
          }
          return 0;
        }
      })
      .map((star, index) => (
        <StarSetContainer key={index}>
          <Spacer width={15} height={0} />
          <StyledStarRatings
            rating={star.value}
            starSize={10}
            emptyStarColor={"transparent"}
          />
          <CommentContainer>
            <CommentText isEmpty={!Boolean(star.comment)}>
              {star.comment ? star.comment : textJson.noCommentText}
            </CommentText>
          </CommentContainer>
        </StarSetContainer>
      ));

    return (
      <StarListContainer>
        {/* <h1>{title}</h1> */}
        <StarList>{starItems}</StarList>
      </StarListContainer>
    );
  }

  useEffect(() => {
    trackPromise(
      getMyStarSetInfo()
        .then((data) => {
          setStarsetData(data);
        })
        .catch((error) => {
          console.log(error);
        })
    );
    setProfileImg();
  }, []);

  function getMyStarSetInfo() {
    const requester = new api.StarAPI(idToken);
    return new Promise((resolve, reject) => {
      requester
        .get_starset("me")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 404") {
          }
          reject();
        });
    });
  }

  const [profileImgUrl, setProfileImgUrl] = useState("");

  function setProfileImg() {
    getFBImgUrl("me", facebookAccessToken).then(setProfileImgUrl);
  }
  return (
    <LogedContainer>
      <InnerContainer>
        <Spacer width={0} height={height * 0.1} />
        <Title>{textJson.title}</Title>
        {isEnough ? (
          <StarSetList>{starsetItems}</StarSetList>
        ) : (
          <div>
            <p>{starset.num_of_valid_stars + "/" + enoughNum}</p>
            <NotEnoughText>
              {textContents.formatString(
                textJson.notEnoughText,
                enoughNum - starset.num_of_valid_stars
              )}
            </NotEnoughText>
          </div>
        )}
      </InnerContainer>
    </LogedContainer>
  );
}

const LogedContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${colors.greyColor};
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
`;

const Title = styled.h1`
  color: ${colors.themeColor};
  font-weight: 200;
  font-size: 28px;
`;

const NotEnoughText = styled.p`
  text-align: center;
`;

const CopyBtn = styled(StyledButton)`
  color: black;
  background-color: lightgray;
  &:hover {
    cursor: pointer;
  }
`;

const StarSetList = styled.ul`
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
  height: ${height * 0.5}px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 6px;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(255, 218, 62, 0.8);
    border-radius: 6px;
  }
`;

const StarListContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StarList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  height: 10%;
  overflow: auto;
`;

const StarSetContainer = styled.li`
  display: flex;
  margin: 10px 0;
  width: 97%;
  min-height: 40px;
  border-radius: 15px;
  padding: 4px 0px 4px 0px;
  background-color: ${colors.greyColor};
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;

const CommentContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
`;

const CommentText = styled.div`
  margin: auto 20px;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  word-break: break-all;
  color: ${(props) => (props.isEmpty ? "#9D9D9D" : "white")};
  text-align: right;
`;

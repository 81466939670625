import axios from "axios";
import qs from "querystring";
import getServerAddress from "./getServerAddress";

const server_address = getServerAddress();
const api_address = server_address + "/api/v1";

export class UserAPI {
  constructor(FirebaseIDToken) {
    this.userAxios = axios.create({
      baseURL: api_address + "/user",
      headers: { Authorization: "FirebaseIDToken " + FirebaseIDToken },
      timeout: 5000,
    });
  }

  login(username, email, profile) {
    return this.userAxios
      .put("/login/", {
        username: username,
        email: email,
        profile: profile,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
  }
  get_profile_with_facebook_uid(facebookUid) {
    return this.userAxios
      .get("/" + facebookUid + "/?facebook=True")
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
  }
  get_friends() {
    return this.userAxios
      .get("/friends/")
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
  }
  set_friends(facebook_uid_list = [], kakao_uid_list = []) {
    return this.userAxios
      .post("/friends/", {
        facebook_uid_list: facebook_uid_list,
        kakao_uid_list: kakao_uid_list,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
  }

  get_my_profile() {
    return this.userAxios
      .get("/me/")
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
  }
}

import { createGlobalStyle } from "styled-components";
import RobotoThin from "./Roboto-Thin.ttf";
import RobotoRegualr from "./Roboto-Regular.ttf";
import RobotoBold from "./Roboto-Black.ttf";

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: url(${RobotoThin} format('ttf');
             url(${RobotoRegualr} format('ttf');
             url(${RobotoBold} format('ttf');
    }
`;

export default FontStyles;

import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { useAlert } from "react-alert";
import getMobileOperatingSystem from "../services/getMobileOperatingSystem";
import { Container, Spacer, StyledButton } from "./styled";
import { textContents } from "../assets/textContents";
import getOutFromInAppBrowser from "../services/getOutFromInAppBrowser";
import Modal from "react-modal";
import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { useSelector, useDispatch } from "react-redux";
import {
  getFirebaseUser,
  getStarMeUser,
  getIdToken,
  getIsKor,
} from "../features/user/userSlice";
import getServerAddress from "../api/getServerAddress";
import {
  kakaoLogin,
  kakaoSetToken,
  getKakaoFriends,
  setKakaoFriendsAsStarmeFriends,
} from "../services/KakaoSdk";
import { trackPromise } from "react-promise-tracker";
import colors from "../assets/colors.json";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;
Modal.setAppElement(document.getElementById("root"));

export default function KakaoButton(props) {
  const location = window.location.href;
  const idToken = useSelector(getIdToken);
  const [state, setState] = useState({ back_url: location, ...props.state });

  const isInstagram = navigator.userAgent.includes("Instagram");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  useEffect(() => {
    if (query.has("state")) {
      const state_str = query.get("state");
      const state_json = JSON.parse(state_str);
      setState({ ...state, ...state_json });
      kakaoSetToken(state_json.kakao_access_token);

      firebase.auth().signInWithCustomToken(state_json.firebase_token);
    }
  }, []);

  useEffect(() => {
    if (idToken) {
      getAndSetKakaoFriends();
    }
  }, [idToken]);

  function getAndSetKakaoFriends() {
    getKakaoFriends().then(function (response) {
      const uid_list = response.elements.map((friend) => {
        return friend.id;
      });
      trackPromise(
        setKakaoFriendsAsStarmeFriends(uid_list, idToken).then((data) => {})
      );
    });
  }

  function onClickKakaoButton() {
    kakaoLogin(state);
  }

  const isKor = useSelector(getIsKor);
  if (!isKor) {
    return null;
  }

  return (
    <KakaoLoginButton
      {...props}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        onClickKakaoButton();
      }}
    >
      <KakaoButtonImg src={require("../assets/kakao-talk.png").default} />
      <KakaoText>카카오톡 로그인</KakaoText>
    </KakaoLoginButton>
  );
}

const KakaoButtonImg = styled.img`
  height: 14px;
  resize-mode: contain;
`;

const KakaoLoginButton = styled.button`
  width: 100%;
  height: ${height * 0.065}px;
  border-radius: 15px;
  color: black;
  font-size: ${0.04 * width}px;
  border-width: 0;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  background-color: ${colors.themeColor};
  box-shadow: 0px 0px 4px ${colors.greyColor};
`;

const KakaoText = styled.p`
  font-size: ${height * 0.02}px;
  text-align: right;
  width: 100%;
`;

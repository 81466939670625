import React, { useEffect, useState } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import { ProfileImage } from "../components/styled";
import { useLocation } from "react-router-dom";
import * as api from "../api/_index";
import {
  getFirebaseUser,
  getStarMeUser,
  getIdToken,
  updateStarMeUser,
  logIn,
  logOut,
  selectUser,
} from "../features/user/userSlice";
import { textContents } from "../assets/textContents";
import StyledStarRatings from "../components/StyledStarRatings";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { FaLink } from "react-icons/fa";

import colors from "../assets/colors.json";
import KakaoShareButton from "../components/KakaoShareBtn";
import getUserLocale from "get-user-locale";
import {
  StyledButton,
  StyledLink,
  Spacer,
  Container,
} from "../components/styled";

import { checkLoginState, getFBImgUrl } from "../services/FacebookSdk";
import no_profile_pic from "../assets/no-profile-pic.jpg";

import { Link } from "react-router-dom";
import Slogan from "../components/Slogan";
import ShareBunddle from "../components/ShareBunddle";
import { LogScreen } from "../services/analytics";

const windowWidth = Math.min(400, window.innerWidth);
const windowHeight = window.innerHeight;

export function FriendDetailPage(props) {
  const logScreen = new LogScreen("FriendDetail");

  useEffect(() => {
    logScreen.screenLog();
  }, []);
  const textJson = textContents.friendDetailPage;
  const firebaseUser = useSelector(getFirebaseUser);
  const idToken = useSelector(getIdToken);
  const starMeUser = useSelector(getStarMeUser);
  const dispatch = useDispatch();
  const starsetEncodedId = props.match.params.encodedId;

  const [starSetInfo, setStarSetInfo] = useState({});
  const enoughNum = 1;
  const isEnough = starSetInfo.num_of_valid_stars >= enoughNum;
  const [isLoading, setIsLoading] = useState(true);
  const friendName = starSetInfo.owner ? starSetInfo.owner.display_name : "";
  const defaultValue = 5;
  const [stars, setStars] = useState(defaultValue);
  const [comment, setComment] = useState("");
  const [isExist, setIsExist] = useState(true);

  function getStarSetInfo(encodedId) {
    const requester = new api.StarAPI(idToken);
    return new Promise((resolve, reject) => {
      requester
        .get_starset(encodedId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 404") {
            setIsExist(false);
          }
          reject();
        });
    });
  }

  const [profileImgUrl, setProfileImgUrl] = useState("");

  useEffect(() => {
    getStarSetInfo(starsetEncodedId).then((info) => {
      setStarSetInfo(info);
      setProfileImgUrl(info.owner.profile_image);

      // setStarOwnerProfileImg(info);
    });

    setIsLoading(false);
  }, []);

  function StarSet(props) {
    const data = props.data;
    const title = data.title;
    const starListData = data.stars;
    console.log(starListData);
    const sortedStarListData = starListData.sort((a, b) => {
      if (a.value > b.value) {
        return -1;
      } else if (a.value < b.value) {
        return 1;
      } else {
        if (a.comment) {
          return -1;
        }
        return 0;
      }
    });

    const starItems = sortedStarListData.map((star, index) => (
      <StarListItem key={index}>
        <Spacer width={15} height={0} />
        <StyledStarRatings
          rating={star.value}
          starSize={10}
          emptyStarColor={"transparent"}
        />
        <CommentContainer>
          <CommentText isEmpty={!Boolean(star.comment)}>
            {star.comment ? star.comment : textJson.noCommentText}
          </CommentText>
        </CommentContainer>
      </StarListItem>
    ));

    return <StarList>{starItems}</StarList>;
  }

  return (
    <FriendDetailContainer>
      <TopContainer>
        {/* <ProfileContainer>
        <StarProfileImage
          src={profileImgUrl != "" ? profileImgUrl : no_profile_pic}
        />
        {friendName ? <p>{friendName} </p> : null}
      </ProfileContainer>
      <p>
        {textContents.formatString(
          textJson.peopleText,
          starSetInfo.num_of_valid_stars ? starSetInfo.num_of_valid_stars : 0
        )}
      </p>

      <StyledStarRatings
        rating={isEnough ? starSetInfo.average : 0}
        isEnough={isEnough}
      /> */}
        <Spacer width={0} height={windowHeight * 0.05} />
        <FriendInfo>
          <FriendInfoLeft>
            <FriendName>{friendName}</FriendName>
            <Spacer width={0} height={20} />
            <StyledStarRatings
              rating={isEnough ? starSetInfo.average : 0}
              isEnough={isEnough}
              starSize={windowHeight * 0.045}
              starRatedColor={colors.greyColor}
              starEmptyColor={"transparent"}
            />
            <Spacer width={0} height={7} />
            <FriendText>
              {textContents.formatString(
                textJson.peopleText,
                starSetInfo.num_of_valid_stars
                  ? starSetInfo.num_of_valid_stars
                  : 0
              )}
            </FriendText>
            <Spacer width={0} height={3} />
            <FriendText>
              {textContents.formatString(
                textJson.starText,
                starSetInfo.num_of_valid_stars ? starSetInfo.average : 0
              )}
            </FriendText>
          </FriendInfoLeft>
          <FriendInfoRight>
            <FriendProfileImage
              src={profileImgUrl ? profileImgUrl : no_profile_pic}
            />
          </FriendInfoRight>
        </FriendInfo>
        <Spacer height={0.05 * windowHeight} />
        {isEnough ? (
          <StarSet data={starSetInfo} />
        ) : (
          <div>
            <NotEnoughText>
              {textContents.formatString(
                textJson.notEnoughText,
                enoughNum - starSetInfo.num_of_valid_stars
              )}
            </NotEnoughText>
          </div>
        )}
        {isEnough ? null : (
          <>
            <p>
              {textContents.formatString(textJson.shareText, {
                name: friendName,
              })}
            </p>
            <ShareBunddle
              receiverName={friendName}
              encodedId={starsetEncodedId}
            />
          </>
        )}
      </TopContainer>
      <BottomContainer>
        <ActionButtonsContainer>
          <StarPageButton>
            <MyStarRatePageLink to={"/me/friends"}>
              {textJson.friendPageButton}
            </MyStarRatePageLink>
          </StarPageButton>
          <Spacer width={25} height={0} />
          <FriendsPageButton
            onClick={() => {
              logScreen.log("click_MyFriends", {});
            }}
          >
            <FriendPageLink to={"/" + starsetEncodedId}>
              {textJson.starScreenButton}
            </FriendPageLink>
          </FriendsPageButton>
        </ActionButtonsContainer>
      </BottomContainer>
    </FriendDetailContainer>
  );
}

const FriendDetailContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${colors.themeColor};
`;

const TopContainer = styled(Container)`
  width: 90%;
`;

const NotEnoughText = styled.p`
  text-align: center;
`;

const ShareText = styled.p`
  margin: 0;
`;

const CopyBtn = styled(StyledButton)`
  border-radius: 100px;
  width: 50px;
  height: 50px;
  color: black;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: lightgray;
  &:hover {
    cursor: pointer;
  }
`;

const FriendStarInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${colors.greyColor};
`;

const FriendProfileImage = styled(ProfileImage)`
  height: ${windowHeight * 0.14}px;
  width: ${windowHeight * 0.14}px;
  resize-mode: contain;
  margin: 0;
`;

const FriendInfo = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  width: 100%;
  height: ${windowHeight * 0.2}px;
`;

const FriendInfoLeft = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-start;
`;

const FriendName = styled.div`
  font-size: 18px;
  color: ${colors.greyColor};
`;

const FriendText = styled.div`
  font-size: 12px;
  color: ${colors.greyColor};
`;

const FriendInfoRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
`;

const BottomContainer = styled(Container)`
  justify-content: flex-end;
  align-items: center;
  flex: 0;
  width: 90%;
`;
//ActionButtons

const ActionButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const ActionButton = styled(StyledButton)`
  width: 50%;
  height: ${windowHeight * 0.05}px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  align-content: center;
  border: none;
`;

const StarPageButton = styled(ActionButton)`
  background-color: lightgray;
`;

const StarPageLink = styled(StyledLink)`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  color: black;
  font-size: ${windowHeight * 0.015}px;
  font-weight: 500;
`;

const MyStarRatePageLink = styled(StarPageLink)`
  justify-content: center;
`;

const FriendsPageButton = styled(ActionButton)`
  background-color: ${colors.buttonsColor};
`;

const FriendPageLink = styled(StyledLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: ${windowHeight * 0.015}px;
  font-weight: 500;
`;

const StarList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  overflow: auto;
  display: red;
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: transparent;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.greyColor};
    border-radius: 6px;
  }
`;

const StarListItem = styled.li`
  display: flex;
  margin: 10px 0;
  width: 97%;
  min-height: 40px;
  border-radius: 15px;
  padding: 4px 0px 4px 0px;
  background-color: ${colors.greyColor};
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;

const CommentContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
`;

const CommentText = styled.div`
  margin: auto 20px;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-all;
  color: ${(props) => (props.isEmpty ? "#9D9D9D" : "white")};
  text-align: right;
`;

import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { useAlert } from "react-alert";
import getMobileOperatingSystem from "../services/getMobileOperatingSystem";
import { Container, Spacer, StyledButton } from "./styled";
import { textContents } from "../assets/textContents";
import getOutFromInAppBrowser from "../services/getOutFromInAppBrowser";

import Modal from "react-modal";
import { useState } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const width = window.innerWidth;
const height = window.innerHeight;
Modal.setAppElement(document.getElementById("root"));
export default function FacebookButton(props) {
  const instaText = textContents.general.instaText;
  const textJson = textContents.sorryText;
  const text = props.text;
  const alert = useAlert();
  const searchParams = props.searchParams;

  const isInstagram = navigator.userAgent.includes("Instagram");

  const isIOS = getMobileOperatingSystem() == "IOS";
  const isKakao = navigator.userAgent.includes("KAKAOTALK");

  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "0",
      transform: "translate(-50%, 0%)",
      width: "85%",
      maxWidth: 425,
      marign: 0,
      padding: 0,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      display: "flex",
      flexDirection: "column",
      alignContents: "center",
      alignItem: "center",
      justifyContent: "center",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.5)",
    },
  };

  const backStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {},
  };

  return (
    <BtnContainer>
      <Modal
        isOpen={isModalOpen}
        style={modalStyles}
        onRequestClose={() => {
          toggleModal();
        }}
        closeTimeoutMS={500}
      >
        {isInstagram ? (
          <Container>
            <Title>{textJson.instaTitle}</Title>
            <SubTitle>{textJson.subTitle}</SubTitle>
            <StyledButton
              onClick={() => {
                getOutFromInAppBrowser(searchParams);
              }}
            >
              {textJson.instaEscape}
            </StyledButton>
            <Spacer height={10}></Spacer>
          </Container>
        ) : null}
        {isKakao ? (
          <Container>
            <Title>{textJson.kakaoTitle}</Title>
            <SubTitle>{textJson.subTitle}</SubTitle>
            <StyledButton
              onClick={() => {
                getOutFromInAppBrowser(searchParams);
              }}
            >
              {textJson.kakaoEscape}
            </StyledButton>
            <Spacer height={10}></Spacer>
          </Container>
        ) : null}
      </Modal>
      <FacebookLoginButton
        isKakao={isKakao}
        {...props}
        onClick={() => {
          if (isInstagram || (!isIOS && isKakao)) {
            setIsModalOpen(true);
          } else {
            props.onClick();
          }
        }}
      >
        <Spacer width={20} height={0} />
        <FacebookIcon src={require("../assets/fb_icon.png").default} />
        <FacebookText>{text}</FacebookText>
        <Spacer width={20} height={0} />
      </FacebookLoginButton>
    </BtnContainer>
  );
}

const BtnContainer = styled.div`
  width: 100%;
`;

const FacebookLoginButton = styled.button`
  width: 100%;
  height: ${height * 0.065}px;
  border-radius: 15px;
  background-color: #3b5998;
  color: white;
  font-size: ${0.04 * width}px;
  border-width: 0;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.h1`
  text-align: center;
  white-space: pre-wrap;
`;
const SubTitle = styled.p``;

const InstaText = styled.p`
  color: red;
  white-space: pre-wrap;
  text-align: center;
`;

const FacebookIcon = styled.img`
  height: 12px;
  resize-mode: contain;
`;

const FacebookText = styled.p`
  font-size: ${height * 0.02}px;
  text-align: right;
  width: 100%;
`;

const AlertMesseage = styled.p`
  white-space: pre-wrap;
  text-align: center;
`;

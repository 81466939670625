import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../assets/colors.json";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const ProfileImage = styled.img`
  border-radius: 500px;
  width: 20%;
  aspect-ratio: 1;
  margin-top: 10px;
`;

export const StyledButton = styled.button`
  border-radius: 15px;
  width: 50%;
  height: 50px;
  background-color: ${colors.buttonsColor};
  color: white;
  font-size: 18px;
  border: none;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  font-style: none;
  color: white;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const Spacer = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const Highlited = styled.mark`
  display: inline-block;
  line-height: 0.3em;
  padding-bottom: 0.4em;
  background-color: #ffcc00;
`;

import server_config from "./server_config.json";

const getServerAddress = () => {
  if (process.env.NODE_ENV === "development") {
    console.log("this is dev mode");
    return server_config.server_address.development;
  } else {
    return server_config.server_address.production;
  }
};

export default getServerAddress;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "firebase/auth";
import { useSelector } from "react-redux";
import * as api from "../api/_index";
import { getIdToken } from "../features/user/userSlice";
import { textContents } from "../assets/textContents";
import colors from "../assets/colors.json";

import { Container, Spacer } from "../components/styled";

import Friend from "../components/Friend";

import { trackPromise } from "react-promise-tracker";
import {
  kakaoLogin,
  getKakaoFriends,
  setKakaoFriendsAsStarmeFriends,
} from "../services/KakaoSdk";
import { LogScreen } from "../services/analytics";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

export function FriendsPage(props) {
  const logScreen = new LogScreen("FriendsPage");

  useEffect(() => {
    logScreen.screenLog();
  }, []);
  const textJson = textContents.friendsPage;
  const idToken = useSelector(getIdToken);

  useEffect(() => {
    getMyFriends();
  }, []);

  const [friendsData, setFriendsData] = useState([]);

  var friendsItems = friendsData.map((friend, index) => (
    <FriendItem key={index}>
      <Friend data={friend} />
    </FriendItem>
  ));

  useEffect(() => {
    friendsItems = friendsData.map((friend, index) => (
      <FriendItem key={index}>
        <Friend
          onClick={() => {
            logScreen.log("clickFriend");
          }}
          data={friend}
        />
      </FriendItem>
    ));
  }, [friendsData]);

  function getMyFriends() {
    trackPromise(
      getMyFriendsData().then((data) => {
        setFriendsData(data);
      })
    );
  }

  function getMyFriendsData() {
    const userAPI = new api.UserAPI(idToken);
    return new Promise((resolve, reject) => {
      userAPI
        .get_friends()
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log("error in App.js");
          console.log(error);
        });
    });
  }

  useEffect(() => {
    getAndSetKakaoFriends();
  }, []);

  function getAndSetKakaoFriends() {
    getKakaoFriends().then(function (response) {
      const uid_list = response.elements.map((friend) => {
        return friend.id;
      });
      trackPromise(
        setKakaoFriendsAsStarmeFriends(uid_list, idToken).then((data) => {
          setFriendsData(data);
        })
      );
    });
  }

  return (
    <StartConatiner>
      <InnerContainer>
        <Spacer width={0} height={height * 0.1} />
        <Title>{textJson.title}</Title>
        <Spacer width={0} height={height * 0.03} />
        <FirendsList>{friendsItems}</FirendsList>
      </InnerContainer>
    </StartConatiner>
  );
}

const StartConatiner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${colors.greyColor};
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
`;

const FirendsList = styled.ul`
  max-height: ${height * 0.5}px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: transparent;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 218, 62, 0.8);
    border-radius: 6px;
  }
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
`;

const FriendItem = styled.li`
  margin-right: ${width * 0.06}px;
`;

const Title = styled.h1`
  color: ${colors.themeColor};
  font-weight: 200;
  font-size: 28px;
`;

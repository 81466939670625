import firebase from "firebase/app";
import "firebase/analytics";

export function logEvent(event, params = {}) {
  firebase.analytics().logEvent(event, params);
}

export class LogScreen {
  constructor(screen = "") {
    this.screen = screen;
  }

  log(event, params = {}) {
    firebase
      .analytics()
      .logEvent((this.screen ? this.screen + "_" : "") + event, params);
  }

  screenLog(params = {}) {
    firebase
      .analytics()
      .logEvent((this.screen ? this.screen + "_" : "") + "page_view", params);
  }
}

import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";
import { useAlert } from "react-alert";
import getMobileOperatingSystem from "../services/getMobileOperatingSystem";
import { Container, Spacer, StyledButton } from "./styled";
import { textContents } from "../assets/textContents";
import getOutFromInAppBrowser from "../services/getOutFromInAppBrowser";
import Modal from "react-modal";
import { useState } from "react";
import FacebookButton from "./FacebookButton";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

export default function LoginButton(props) {
  const instaText = textContents.general.instaText;
  const text = props.text;
  const alert = useAlert();

  const isInstagram = navigator.userAgent.includes("Instagram");

  const isKakao = navigator.userAgent.includes("KAKAOTALK");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "60%",
      maxWidth: 300,
    },
  };

  return (
    <Container>
      <Modal
        isOpen={isModalOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h1>로그인</h1>
        <FacebookButton text={"페이스북으로 로그인"}></FacebookButton>
      </Modal>
      <StyledButton
        isKakao={isKakao}
        {...props}
        onClick={() => {
          props.onClick();
          setIsModalOpen(true);
        }}
      >
        <FacebookText>로그인</FacebookText>
      </StyledButton>
    </Container>
  );
}

const FacebookLoginButton = styled.button`
  width: 60%;
  height: ${(props) => (props.isKakao ? width * 0.08 : width * 0.12)}px;
  border-radius: 10px;
  background-color: #1877f2;
  color: white;
  font-size: ${0.04 * width}px;
  border-width: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const InstaText = styled.p`
  color: red;
  white-space: pre-wrap;
`;

const FacebookText = styled.p`
  margin: auto 10px;
`;

const AlertMesseage = styled.p`
  white-space: pre-wrap;
  text-align: center;
`;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as screens from "./screens/_index";
import * as api from "./api/_index";
import {
  getFirebaseUser,
  getStarMeUser,
  logIn,
  logOut,
  updateStarMeUser,
  setFacebookAccessToken,
  setIsKor,
  getIsKor,
} from "./features/user/userSlice";
import {
  initFacebookSdk,
  checkLoginState,
  facebookLogout,
} from "./services/FacebookSdk";
import getUserLocale from "get-user-locale";
import { positions, Provider as AlertProvider, types } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import { textContents } from "./assets/textContents";

import SwitchButton from "react-switch";

import colors from "./assets/colors.json";
import { Spacer, StyledButton } from "./components/styled";
import LoadingSpinner from "./components/LoadingSpinner";
import LoginNotice from "./components/loginNotice";
import LanguageSwitchButton from "./components/LanguageSwitchButton";

import { firebaseConfig } from "./Constants";
// Alert optional configuration
const options = {
  position: positions.MIDDLE,
  timeout: 2000,
  offset: "15px",
  type: types.SUCCESS,
};

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const analytics = firebase.analytics();
  //check server is alive
  useEffect(() => {
    api.ping();
  }, []);

  //Facebook Sdk initialization
  useEffect(() => {
    initFacebookSdk()
      .then((response) => {
        window.FB.Event.subscribe("auth.authResponseChange", (response) => {
          checkLoginState(response);
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            setToken(accessToken);
          }
        });
      })
      .then(() => {});
  }, []);

  function setToken(facebookAccessToken) {
    dispatch(
      setFacebookAccessToken({ facebookAccessToken: facebookAccessToken })
    );
  }

  const firebaseUser = useSelector(getFirebaseUser);
  const starMeUser = useSelector(getStarMeUser);

  const generalTextJson = textContents.general;
  const userLocale = getUserLocale();

  const [language, setLanguage] = useState(textContents.getLanguage());
  function handelChange(checked) {
    if (language == "ko") {
      textContents.setLanguage("en");
      setLanguage("en");
    } else {
      textContents.setLanguage("ko");
      setLanguage("ko");
    }
  }
  useEffect(() => {
    const isKorTo = userLocale == "ko-KR" || language == "ko";
    dispatch(setIsKor(isKorTo));
  }, []);
  const isKor = useSelector(getIsKor);

  //Initialize Kakao SDK
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (firebaseUser) {
      if (firebaseUser) {
        console.log("logIn");
        const firebaseUserJson = firebaseUser.toJSON();
        firebaseUser.getIdToken().then((firebaseIdToken) => {
          dispatch(
            logIn({ firebaseUser: firebaseUserJson, idToken: firebaseIdToken })
          );
          const userAPI = new api.UserAPI(firebaseIdToken);
          userAPI
            .login(firebaseUserJson.uid, firebaseUserJson.email, {
              display_name: firebaseUserJson.displayName,
            })
            .then((response) => {
              dispatch(updateStarMeUser({ starMeUser: response.data }));
              setIsLoading(false);
            })
            .catch((error) => {
              console.log("error in App.js");
              console.log(error);
              setIsLoading(false);
            });
          return { user: firebaseUserJson, idToken: firebaseIdToken };
        });
      } else {
        console.log("logOut");
        dispatch(logOut());
        setIsLoading(false);
      }
    });
  }, []);

  function onLogoutButton() {
    // facebookLogout();
    firebase.auth().signOut();
    firebase.analytics().logEvent("logout");
  }
  let matchIntroPage = useRouteMatch({
    path: "/",
    exact: true,
    sensitive: true,
  });
  let isIntroPage =
    !isLoading && matchIntroPage && !(firebaseUser && starMeUser);

  let isStarPage = useRouteMatch({
    path: "/:encodedId/result",
    exact: true,
    sensitive: true,
  });
  return (
    <AppBackground>
      <AppContainer className="App">
        <LoadingSpinner />
        <AppFooter isIntroPage={isIntroPage}>
          <div>
            {starMeUser ? (
              <LogoutBtn onClick={onLogoutButton}>
                {generalTextJson.logout}
              </LogoutBtn>
            ) : null}
          </div>
          {isKor ? (
            <LanguageSwitchButton
              onChange={handelChange}
              checked={language == "ko"}
            />
          ) : (
            <div></div>
          )}
        </AppFooter>
        <AppHeader>
          <AlertProvider template={AlertTemplate} {...options}>
            {isLoading ? (
              <screens.LoadingPage />
            ) : (
              <Switch>
                {firebaseUser && starMeUser ? (
                  <Route
                    exact
                    path="/me/detail"
                    component={screens.MyStarDetailPage}
                  />
                ) : null}
                {firebaseUser && starMeUser ? (
                  <Route
                    exact
                    path="/me/friends"
                    component={screens.FriendsPage}
                  />
                ) : null}
                {firebaseUser && starMeUser ? (
                  <Route
                    path="/:encodedId/detail"
                    component={screens.FriendDetailPage}
                  />
                ) : null}
                <Route
                  path="/:encodedId/result"
                  component={screens.StarResultPage}
                />
                <Route exact path="/:encodedId" component={screens.StarPage} />
                {firebaseUser && starMeUser ? (
                  <Route path="/" component={screens.MyPage} />
                ) : (
                  <Route path="/" component={screens.IntroPage} />
                )}
              </Switch>
            )}
          </AlertProvider>
          <Contact>
            <Spacer width={25} height={0} />
            {isIntroPage && <Version>ver 0.0.2.</Version>}
            <Email>contact : starme.site@gmail.com</Email>
            <Spacer width={25} height={0} />
          </Contact>
        </AppHeader>
      </AppContainer>
    </AppBackground>
  );
}

export default App;

const AppBackground = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  font-family: "NotoSansKR";
  background-color: ${colors.backgroundColor};
`;

const AppContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  background-color: ${colors.themeColor};
`;

const AppFooter = styled.div`
  display: flex;
  width: 100%;
  min-height: 30px;
  padding-top: ${height * 0.01}px;
  padding-bottom: ${height * 0.01}px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isIntroPage ? colors.themeColor : colors.greyColor};
`;

const AppHeader = styled.header`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: 20px;
  padding: 0;
`;

const LogoutBtn = styled(StyledButton)`
  color: ${colors.greyColor};
  background-color: ${colors.themeColor};
  border: none;
  border-radius: 15px;
  font-size: 10px;
  width: 100%;
  padding: 2;
  height: ${height * 0.035}px;
  margin-left: ${width * 0.04}px;
`;

const Contact = styled.div`
  display: flex;
  color: ${(props) => (props.isIntroPage ? "white" : colors.greyColor)};
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) =>
    props.isIntroPage ? colors.greyColor : colors.themeColor};
`;

const Version = styled.p`
  font-size: 10px;
  flex: 1;
  text-align: left;
`;

const Email = styled(Version)`
  text-align: right;
  flex: 2;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { ProfileImage } from "../components/styled";
import * as api from "../api/_index";
import {
  getFirebaseUser,
  getStarMeUser,
  getIdToken,
  updateStarMeUser,
  logIn,
  logOut,
  selectUser,
  getIsKor,
} from "../features/user/userSlice";
import { textContents } from "../assets/textContents";
import StyledStarRatings from "../components/StyledStarRatings";
import colors from "../assets/colors.json";
import getUserLocale from "get-user-locale";
import { LogScreen } from "../services/analytics";

import {
  StyledButton,
  StyledLink,
  Spacer,
  Container,
} from "../components/styled";

import { getFBImgUrl } from "../services/FacebookSdk";
import no_profile_pic from "../assets/no-profile-pic.jpg";

import ShareBunddle from "../components/ShareBunddle";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

const isMobile = window.innerWidth <= 700;

export function MyPage(props) {
  const logScreen = new LogScreen("MyPage");

  useEffect(() => {
    logScreen.screenLog();
  }, []);

  const textJson = textContents.myPage;
  const firebaseUser = useSelector(getFirebaseUser);
  const starMeUser = useSelector(getStarMeUser);
  const dispatch = useDispatch();

  const isKor = useSelector(getIsKor);
  console.log(starMeUser);
  const starset = starMeUser.profile.starsets[0];
  const encodedId = starset.encoded_id;
  const enoughNum = 5;
  const isEnough = starset.num_of_valid_stars >= enoughNum;
  const host =
    process.env.NODE_ENV == "development"
      ? "http://localhost:3000"
      : "https://starme.site";
  const shareUrl = host + "/" + encodedId + "/";

  const idToken = useSelector(getIdToken);

  function getMyInfo() {
    const userAPI = new api.UserAPI(idToken);
    userAPI
      .get_my_profile()
      .then((response) => {
        dispatch(updateStarMeUser({ starMeUser: response.data }));
      })
      .catch((error) => {
        console.log("error getting my profile");
        console.log(error);
      });
  }
  const facebookAccessToken = useSelector(
    (state) => state.user.facebookAccessToken
  );

  const [starsetData, setStarsetData] = useState([]);
  const starsetItems = starsetData.map((starset, index) => (
    <li key={index}>
      <StarSet data={starset} />
    </li>
  ));

  useEffect(() => {
    getMyInfo();
    setProfileImg();
  }, []);

  useEffect(() => {
    getMyStarSetInfo().then((data) => {
      setStarsetData(data);
      console.log(data);
    });
  }, []);

  function getMyStarSetInfo() {
    const requester = new api.StarAPI(idToken);
    return new Promise((resolve, reject) => {
      requester
        .get_starset("me")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 404") {
          }
          reject();
        });
    });
  }

  function StarSet(props) {
    const data = props.data;
    const title = data.title;
    const starListData = data.stars;
    const starItems = starListData
      .sort((a, b) => {
        if (a.value > b.value) {
          return -1;
        } else if (a.value < b.value) {
          return 1;
        } else {
          if (a.comment) {
            return -1;
          }
          return 0;
        }
      })
      .map((star, index) => (
        <StarSetContainer key={index}>
          <Spacer width={15} height={0} />
          <StyledStarRatings
            rating={star.value}
            starSize={10}
            emptyStarColor={"transparent"}
          />
          <CommentContainer>
            <CommentText isEmpty={!Boolean(star.comment)}>
              {star.comment ? star.comment : textJson.noCommentText}
            </CommentText>
          </CommentContainer>
        </StarSetContainer>
      ));

    return (
      <StarListContainer>
        {/* <h1>{title}</h1> */}
        <StarList>{starItems}</StarList>
      </StarListContainer>
    );
  }

  const [commentOpen, setCommentOpen] = useState(true);

  const toggleComment = () => setCommentOpen(!commentOpen);

  const [profileImgUrl, setProfileImgUrl] = useState("");

  function setProfileImg() {
    if (starMeUser.profile) {
      setProfileImgUrl(starMeUser.profile.profile_image);
    } else {
      getFBImgUrl("me", facebookAccessToken).then((url) => {
        if (url != "") {
          setProfileImgUrl(url);
        }
      });
    }
  }

  return (
    <Container>
      <TopContainer>
        <Spacer width={0} height={height * 0.03} />
        <ProfileContainer>
          <Spacer width={width * 0.05} height={0} />
          <WelcomeContainer>
            <WelcomeText>{textJson.welcomeText}</WelcomeText>
            <WelcomeName>
              <b>{firebaseUser.displayName}</b>
              {textJson.welcomeName}
            </WelcomeName>
            {!isEnough && (
              <>
                <Spacer width={0} height={18} />
                <NotEnoughText>
                  {textContents.formatString(
                    textJson.peopleText,
                    starset.num_of_valid_stars
                  )}
                </NotEnoughText>
                <Spacer width={0} height={5} />
                <NotEnoughText>
                  {textContents.formatString(
                    textJson.notEnoughText,
                    enoughNum - starset.num_of_valid_stars
                  )}
                </NotEnoughText>
              </>
            )}
          </WelcomeContainer>
          <MyPageProfileImage
            src={profileImgUrl ? profileImgUrl : no_profile_pic}
          />
          <Spacer width={width * 0.05} height={0} />
        </ProfileContainer>
        <Spacer width={0} height={isMobile ? 30 : 20} />
        <StarContainer>
          <Spacer width={width * 0.05} height={0} />
          <InnerStarContainer>
            <StarText isEnough={isEnough}>
              {isEnough ? starset.average : "0.00"}
            </StarText>
            <Spacer width={0} height={height * 0.04} />
            <StarPageLink to={"/"}>
              <StyledStarRatings
                rating={isEnough ? starset.average : 0}
                isEnough={isEnough}
                starSize={height * 0.06}
                emptyStarColor={isEnough ? "transparent" : null}
              />
            </StarPageLink>
          </InnerStarContainer>
        </StarContainer>
      </TopContainer>
      <BottomContainer>
        <Spacer width={0} height={20} />
        <CommentTab>
          <CommentTabButton onClick={toggleComment}>
            <CommentTabButtonText>{textJson.commentTab}</CommentTabButtonText>
            <CommentTabButtonIcon
              src={
                commentOpen
                  ? require("../assets/triangle_down.png").default
                  : require("../assets/triangle_up.png").default
              }
            />
          </CommentTabButton>
          <Spacer width={0} height={5} />
          {commentOpen && <StarSetList>{starsetItems}</StarSetList>}
        </CommentTab>
        <Spacer width={0} height={20} />
        <ShareTab>
          <ShareText>{textJson.shareTab}</ShareText>
          <ShareSubText>{textJson.shareText}</ShareSubText>
        </ShareTab>
        <Spacer width={0} height={10} />
        <ShareBunddle
          shareUrl={shareUrl}
          receiverName={firebaseUser.displayName}
          encodedId={encodedId}
          pageName={"MyPage"}
        />
        <Spacer width={0} height={15} />
        <ActionButtonsContainer>
          <StarPageButton>
            <MyStarRatePageLink to={"/me/detail/"}>
              {textJson.detailButtonText}
            </MyStarRatePageLink>
          </StarPageButton>
          <Spacer width={25} height={0} />
          <FriendsPageButton
            onClick={() => {
              logScreen.log("click_MyFriends", {});
            }}
          >
            <FriendPageLink to={"/me/friends/"}>
              {textJson.friendPageButton}
            </FriendPageLink>
          </FriendsPageButton>
        </ActionButtonsContainer>
      </BottomContainer>
    </Container>
  );
}

const TopContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.greyColor};
`;

const ProfileContainer = styled(TopContainer)`
  flex-direction: row;
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;

const WelcomeText = styled.div`
  font-size: 14px;
  font-family: Roboto;
  font-weight: 200;
  line-height: 28px;
  color: ${colors.themeColor};
`;

const StarContainer = styled(ProfileContainer)``;

const InnerStarContainer = styled(WelcomeContainer)``;

const WelcomeName = styled(WelcomeText)`
  font-family: Roboto;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: -1px;
`;

const MyPageProfileImage = styled(ProfileImage)`
  height: ${height * 0.15}px;
  width: ${height * 0.15}px;
  resize-mode: contain;
  margin: 0;
`;

const StarText = styled(WelcomeText)`
  color: ${(props) => (props.isEnough ? colors.themeColor : "#626262")};
  font-family: Roboto;
  font-size: ${height * 0.07}px;
  font-weight: 100;
`;

const NotEnoughText = styled.div`
  font-size: 10px;
  font-weight: 100;
  font-family: Roboto;
  text-align: left;
  color: ${colors.themeColor};
`;

const BottomContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const CommentTab = styled.div``;

const CommentTabButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

const CommentTabButtonText = styled.div`
  font-size: 10px;
  font-weight: 900;
  font-family: Roboto;
  color: ${colors.greyColor};
`;

const CommentTabButtonIcon = styled.img`
  height: 5px;
  width: 10px;
  resize-mode: contain;
`;

const ShareTab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ShareText = styled(CommentTabButtonText)``;

const ShareSubText = styled(ShareText)`
  font-weight: 100;
  font-size: 8px;
`;

//ActionButtons
const ActionButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const ActionButton = styled(StyledButton)`
  width: 50%;
  height: ${height * 0.05}px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  align-content: center;
  border: none;
`;

const StarPageButton = styled(ActionButton)`
  background-color: lightgray;
`;

const StarPageLink = styled(StyledLink)`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  color: black;
  font-size: ${height * 0.015}px;
  font-weight: 500;
`;

const MyStarRatePageLink = styled(StarPageLink)`
  justify-content: center;
`;

const FriendsPageButton = styled(ActionButton)`
  background-color: ${colors.buttonsColor};
`;

const FriendPageLink = styled(StyledLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: ${height * 0.015}px;
  font-weight: 500;
`;

const StarSetList = styled.ul`
  list-style-type: none;
  width: 97%;
  margin: 0;
  padding: 0;
  max-height: ${isMobile ? height * 0.175 : 85}px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: transparent;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
`;

const StarListContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StarList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  height: 10%;
  overflow: auto;
`;

const StarSetContainer = styled.li`
  display: flex;
  margin: 10px 0;
  width: 97%;
  min-height: 40px;
  border-radius: 15px;
  padding: 4px 0px 4px 0px;
  background-color: ${colors.greyColor};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  align-items: center;
`;

const CommentContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
`;

const CommentText = styled.div`
  margin: auto 20px;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  word-break: break-all;
  color: ${(props) => (props.isEmpty ? "#9D9D9D" : "white")};
  text-align: right;
`;

import StarRatings from "react-star-ratings";
import styled from "styled-components";

import colors from "../assets/colors.json";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

export default function StyledStarRatings(props) {
  const filledStarColor = colors.filledStarColor;
  const emptyStarColor =
    props.emptyStarColor != null ? props.emptyStarColor : colors.emptyStarColor;
  const isEnough = props.isEnough != null ? props.isEnough : true;
  const starSize = props.starSize != null ? props.starSize : 50;

  return (
    <StarContainer>
      <MyStarRatings
        {...props}
        starRatedColor={
          props.starRatedColor ? props.starRatedColor : filledStarColor
        }
        starHoverColor={filledStarColor}
        starEmptyColor={
          props.starEmptyColor ? props.starEmptyColor : emptyStarColor
        }
        rating={isEnough && props.rating ? props.rating : 0}
        numberOfStars={5}
        name="rating"
        starDimension={starSize + "px"}
        starSpacing={starSize / 10 + "px"}
      />
    </StarContainer>
  );
}

const StarContainer = styled.div`
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 4px;
`;

const MyStarRatings = styled(StarRatings)`
  width: 100%;
  background-color: #626262;
`;

const QuestionMark = styled.h1`
  position: absolute;
  top: 23%;
  margin: 0 auto;
  left: 0;
  right: 0;
  font-size: 30px;
`;

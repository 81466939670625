import { Highlited } from "../components/styled";
import styled from "styled-components";
import { textContents } from "../assets/textContents";

export default function Slogan(props) {
  var toText = "";
  const textJson = textContents.starPage;
  if (props.text) {
    toText = props.text;
  } else {
    toText = textJson.toText;
  }
  return (
    <Title>
      {textContents.formatString(toText, {
        howManyStar: <Highlited>{textJson.howManyStar}</Highlited>,
      })}
    </Title>
  );
}

const Title = styled.h1`
  white-space: pre-line;
  margin: 0;
  width: 100%;
  font-weight: 900;
  padding: 0;
`;

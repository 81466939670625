import React, { useEffect } from "react";
import kakao_btn from "../assets/buttons/kakao_btn@2x.png";
import styled from "styled-components";

import firebase from "firebase/app";
import "firebase/analytics";

export default function KakaoShareButton(props) {
  const resultJson = props.json;
  function onClick_() {
    firebase.analytics().logEvent("kakao_share_button_clicked");
    if (window.Kakao) {
      const kakao = window.Kakao;
      // 중복 initialization 방지
      if (!kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        kakao.init("e009488e46ca82af972d7f1aea0a28e2");
      }
      kakao.Link.sendCustom({
        templateId: 51586,
        templateArgs: {
          subTitle: resultJson.subTitle ? resultJson.subTitle : "",
          encodedId: resultJson.encodedId,
        },
      });
    }
  }
  return (
    <KakaoBtn
      onClick={() => {
        onClick_();
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <KakaoBtnDiv img={kakao_btn}></KakaoBtnDiv>
    </KakaoBtn>
  );
}

const KakaoBtnDiv = styled.div`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex: 1;

  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
  &:active {
    outline: none;
    cursor: default;
  }
`;

const KakaoBtn = styled.button`
  background-color: transparent;
  border: 0;
  text-decoration: none;
  outline: none;
  margin: 0 3px;
  padding: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  &:active {
    outline: none;
    cursor: default;
  }
`;

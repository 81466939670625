import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import getMobileOperatingSystem from "./services/getMobileOperatingSystem";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import getOutFromInAppBrowser from "./services/getOutFromInAppBrowser";
import FontStyles from "./assets/fonts/fonts";
// initFacebookSdk().then(startApp)

if (navigator.userAgent.includes("Instagram")) {
  if (getMobileOperatingSystem() != "IOS") {
    window.location.href = `https://api.starme.site/redirect?redirect=${window.location.href}`;
  }
}

// function startApp(){
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import firebase from "firebase/app";
import "firebase/auth";
import * as api from "../api/_index";

import { facebookConstant } from "../Constants";
//production
const facebookAppId = facebookConstant.facebookAppId;
const clientAccessToken = facebookConstant.clientAccessToken;

export function initFacebookSdk() {
  return new Promise((resolve, reject) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      console.log("Facebook v10.0 initialized");
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: "v10.0",
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus((response) => {
        if (response.status == "connected") {
          checkLoginState(response)
            .then(() => {
              resolve(response);
            })
            .catch(reject);
          // accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
        } else {
          resolve();
        }
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    // load facebook sdk hashed script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.async = true;
      js.crossorigin = "anonymous";
      js.src =
        "https://connect.facebook.net/en_US/sdk.js?hash=4c69d4eb180dc582bb87d6f0bb27b489&ua=modern_es6";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

export function facebookLogin() {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response) => {
      if (
        response.status == "connected" ||
        response.status == "not_authorized"
      ) {
        checkLoginState(response)
          .then(({ firebaseUserJson, firebaseIdToken }) => {
            resolve({
              response: response,
              firebaseUserJson: firebaseUserJson,
              firebaseIdToken: firebaseIdToken,
            });
          })
          .catch(reject);
      } else {
        window.FB.login(
          (response) => {
            checkLoginState(response)
              .then(({ firebaseUserJson, firebaseIdToken }) => {
                resolve({
                  response: response,
                  firebaseUserJson: firebaseUserJson,
                  firebaseIdToken: firebaseIdToken,
                });
              })
              .catch(reject);
          },
          {
            scope: "email, public_profile, user_friends",
            return_scopes: true,
            enable_profile_selector: true,
          }
        );
      }
    });
    resolve();
  });
}

export function facebookLogout() {
  return new Promise((resolve, reject) => {
    window.FB.logout(resolve);
  });
}

export function checkLoginState(response) {
  return new Promise((resolve, reject) => {
    if (response.authResponse) {
      const facebookUserID = response.authResponse.userID;
      const accessToken = response.authResponse.accessToken;
      var friendsData = [];
      getMyFacebookFriendsWithToken(accessToken).then((data) => {
        friendsData = data;
      });
      // User is signed-in Facebook.
      var unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
        unsubscribe();

        // Check if we are already signed-in Firebase with the correct user.
        if (!isUserEqual(response.authResponse, firebaseUser)) {
          // Build Firebase credential with the Facebook auth token.
          var credential = firebase.auth.FacebookAuthProvider.credential(
            accessToken
          );

          // Sign in with the credential from the Facebook user.
          firebase
            .auth()
            .signInWithCredential(credential)
            .then((userCredential) => {
              const createdFirebaseUser = userCredential.user;
              if (!createdFirebaseUser.email) {
                createdFirebaseUser.updateEmail(
                  `${facebookUserID}@starme.site`
                );
              }
              starMeServerLogin(facebookUserID, createdFirebaseUser).then(
                ({ firebaseUserJson, firebaseIdToken }) => {
                  setStarMeFriends(friendsData, firebaseIdToken)
                    .then(() => {
                      resolve({
                        firebaseUserJson: firebaseUserJson,
                        firebaseIdToken: firebaseIdToken,
                      });
                    })
                    .catch(reject);
                }
              );
            })
            .catch((error) => {
              console.log(error);
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // The email of the user's account used.
              var email = error.email;
              // The firebase.auth.AuthCredential type that was used.
              var credential = error.credential;
              // ...
              reject();
            });
        } else {
          starMeServerLogin(facebookUserID, firebaseUser)
            .then(({ firebaseUserJson, firebaseIdToken }) => {
              setStarMeFriends(friendsData, firebaseIdToken)
                .then(() => {
                  resolve({
                    firebaseUserJson: firebaseUserJson,
                    firebaseIdToken: firebaseIdToken,
                  });
                })
                .catch(reject);
            })
            .catch(reject);
          // User is already signed-in Firebase with the correct user.
        }
      });
    } else {
      // User is signed-out of Facebook.
      resolve();
    }
  });
}

function starMeServerLogin(facebookUserID, firebaseUser) {
  return new Promise((resolve, reject) => {
    if (firebaseUser) {
      const firebaseUserJson = firebaseUser.toJSON();
      var profile_image = "";
      // GET IMG
      getFBImgUrl(facebookUserID).then((url) => {
        if (url) {
          profile_image = url;
        }

        var profileJson = {
          display_name: firebaseUserJson.displayName,
          facebook_uid: facebookUserID,
          profile_image: profile_image,
        };

        firebaseUser.getIdToken().then((fbIdToken) => {
          const userAPI = new api.UserAPI(fbIdToken);
          userAPI
            .login(firebaseUserJson.uid, firebaseUserJson.email, profileJson)
            .then((response) => {
              resolve({
                response: response,
                firebaseUserJson: firebaseUserJson,
                firebaseIdToken: fbIdToken,
              });
            })
            .catch((error) => {
              console.log("error in FacebookSDK.js");
              console.log(error);
              reject();
            });
        });
      });
    }
  });
}

export function getMyFacebookImgUrl() {
  return Promise((resolve, reject) => {
    window.FB.getLoginStatus((response) => {
      if (response) {
        getFBImgUrl("me", response.authResponse.accessToken).then((url) => {
          resolve(url);
        });
        // accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
      } else {
        reject();
      }
    });
  });
}

export function getFBImgUrl(facebookUid, accessToken = clientAccessToken) {
  return new Promise((resolve, reject) => {
    if (facebookUid == "") {
      reject("");
    }
    window.FB.api(
      "/" + facebookUid + "/picture?type=large&redirect=0",
      "GET",
      {
        accessToken: accessToken,
      },
      function (response) {
        if (response && !response.error) {
          resolve(response.data.url);
        } else {
          console.log("IMG ERROR", response);
          reject(response);
        }
      }
    );
  });
}

export function getMyFacebookFriendsWithLogin() {
  return new Promise((resolve, reject) => {
    facebookLogin()
      .then(({ response }) => {
        if (response) {
          getMyFacebookFriendsWithToken
            .then((data) => {
              resolve(data);
            })
            .catch(reject);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}
export function getMyFacebookFriendsWithToken(accessToken) {
  return new Promise((resolve, reject) => {
    getFacebookFriends("me", accessToken)
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
}

export function getFacebookFriends(
  facebookUid,
  accessToken = clientAccessToken
) {
  return new Promise((resolve, reject) => {
    window.FB.api(
      "/" + facebookUid + "/friends",
      "GET",
      {
        accessToken: accessToken,
      },
      function (response) {
        if (response && !response.error) {
          resolve(response);
          return response;
        } else {
          console.log("FRIEND ERROR", response);
          reject(response);
        }
      }
    );
  });
}

function setStarMeFriendsForFirebaseUser(data, firebaseUser) {
  return new Promise((resolve, reject) => {
    firebaseUser.getIdToken().then((idToken) => {
      setStarMeFriends(data, idToken).then(resolve).catch(resolve);
    });
  });
}

function setStarMeFriends(data, idToken) {
  return new Promise((resolve, reject) => {
    var uid_list = new Array(1);
    if (Array.isArray(data)) {
      uid_list = data.map((item) => {
        return item.id;
      });
    }
    sendRequestSetStarMeFriends(uid_list, idToken).then(resolve).catch(reject);
  });
}

function sendRequestSetStarMeFriends(uid_list, idToken) {
  const userAPI = new api.UserAPI(idToken);
  return new Promise((resolve, reject) => {
    userAPI
      .set_friends(uid_list)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("error in App.js");
        console.log(error);
      });
  });
}

function isUserEqual(facebookAuthResponse, firebaseUser) {
  if (firebaseUser) {
    var providerData = firebaseUser.providerData;
    for (var i = 0; i < providerData.length; i++) {
      if (
        providerData[i].providerId ===
          firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
        providerData[i].uid === facebookAuthResponse.userID
      ) {
        // We don't need to re-auth the Firebase connection.
        return true;
      }
    }
  }
  return false;
}

import styled from "styled-components";
import React, { useEffect, useState } from "react";

import { textContents } from "../assets/textContents";

import SwitchButton from "react-switch";
import colors from "../assets/colors.json";

export default function LanguageSwitchButton(props) {
  return (
    <StyledSwitchButton
      {...props}
      uncheckedIcon={
        <SwitchContentContainer>
          <SwitchContent>KO</SwitchContent>
        </SwitchContentContainer>
      }
      checkedIcon={
        <SwitchContentContainer>
          <SwitchContent>EN</SwitchContent>
        </SwitchContentContainer>
      }
      checkedHandleIcon={
        <SwitchContentHandleContainer>
          <SwitchContent>KO</SwitchContent>
        </SwitchContentHandleContainer>
      }
      uncheckedHandleIcon={
        <SwitchContentHandleContainer>
          <SwitchContent>EN</SwitchContent>
        </SwitchContentHandleContainer>
      }
      onColor="#000000"
      onHandleColor={colors.themeColor}
    />
  );
}

const StyledSwitchButton = styled(SwitchButton)`
  margin: auto 10px;
`;

const SwitchContentContainer = styled.div`
  color: ${colors.themeColor};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  margin: 0;
  padding: auto;
`;
const SwitchContentHandleContainer = styled(SwitchContentContainer)`
  color: black;
`;

const SwitchContent = styled.p`
  height: 50%;
  margin: auto;
  text-align: center;
  font-size: 12px;
  vertical-align: middle;
  line-height: normal;
  padding: 0;
`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import * as api from "../api/_index";
import { useSelector, useDispatch } from "react-redux";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import StarRatings from "react-star-ratings";
import { ErrorPage } from "./ErrorPage";
import { FaFacebook } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import no_profile_pic from "../assets/no-profile-pic.jpg";
import logo from "../assets/logo/Starme_logo@1x.svg";
import {
  getFirebaseUser,
  getStarMeUser,
  getIdToken,
  logIn,
  logOut,
  selectUser,
  updateStarMeUser,
} from "../features/user/userSlice";
import { getFBImgUrl, facebookLogin } from "../services/FacebookSdk";
import { textContents } from "../assets/textContents";
import {
  ProfileImage,
  StyledLink,
  Highlited,
  StyledButton,
  Spacer,
} from "../components/styled";
import StyledStarRatings from "../components/StyledStarRatings";

import FacebookButton from "../components/FacebookButton";
import Slogan from "../components/Slogan";
import { LogScreen } from "../services/analytics";
import colors from "../assets/colors.json";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

const accessToken = "522494749141184|593df5ccd551f0df1613e2d0d356971d";

export function StarPage(props) {
  const logScreen = new LogScreen("StarPage");

  useEffect(() => {
    logScreen.screenLog();
  }, []);

  const textJson = textContents.starPage;
  const firebaseUser = useSelector(getFirebaseUser);
  const idToken = useSelector(getIdToken);
  const starMeUser = useSelector(getStarMeUser);
  const dispatch = useDispatch();
  const starsetEncodedId = props.match.params.encodedId;
  const [starSetInfo, setStarSetInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const defaultValue = 5;
  const [stars, setStars] = useState(defaultValue);
  const [comment, setComment] = useState("");
  const [isExist, setIsExist] = useState(true);

  function getStarSetInfo(encodedId) {
    const requester = new api.StarAPI();
    return new Promise((resolve, reject) => {
      requester
        .get_starset(encodedId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 404") {
            setIsExist(false);
          }
          reject();
        });
    });
  }

  const [profileImgUrl, setProfileImgUrl] = useState("");

  useEffect(() => {
    getStarSetInfo(starsetEncodedId).then((info) => {
      setStarSetInfo(info);
      setStarOwnerProfileImg(info);
    });

    setIsLoading(false);
  }, []);

  function setStarOwnerProfileImg(starsetInfo) {
    if (starsetInfo.owner.facebook_uid != "") {
      getFBImgUrl(starsetInfo.owner.facebook_uid, accessToken).then(
        setProfileImgUrl
      );
    }
  }

  const [sendStarSuccess, setSendStarSuccess] = useState(false);
  const [starResponse, setStarResponse] = useState("");
  function onFacebookGiveBtn() {
    if (firebaseUser) {
      giveStarToStarSet(starsetEncodedId, stars, comment).then(
        (responseData) => {
          setStarResponse(responseData);
          setSendStarSuccess(true);
        }
      );
    } else {
      facebookLogin().then(({ firebaseUserJson, firebaseIdToken }) => {
        dispatch(
          logIn({ firebaseUser: firebaseUserJson, idToken: firebaseIdToken })
        );
        giveStarToStarSet(
          starsetEncodedId,
          stars,
          comment,
          firebaseIdToken
        ).then((responseData) => {
          setStarResponse(responseData);
          setSendStarSuccess(true);
        });
      });
    }
  }

  function giveStarToStarSet(
    encodedId,
    starsNumber,
    comment,
    firebaseIdToken = idToken
  ) {
    const requester = new api.StarAPI(firebaseIdToken);
    return requester
      .give_star_to_starset(encodedId, starsNumber, comment)
      .then((response) => {
        return response.data;
      });
  }
  const fbuser = useSelector(getFirebaseUser);
  function onCommentChange(event) {
    setComment(event.target.value);
  }

  function onClickGiveButton() {
    logScreen.log("clickGiveButton");

    giveStarToStarSet(starsetEncodedId, stars, comment).then((responseData) => {
      setStarResponse(responseData);
      setSendStarSuccess(true);
    });
  }

  function onClickAnonymousButton() {
    firebase.analytics().logEvent("star_give_anonymous_button_clicked");
    giveStarToStarSet(starsetEncodedId, stars, comment).then((responseData) => {
      setStarResponse(responseData);
      setSendStarSuccess(true);
    });
  }

  if (isExist) {
    return (
      <StarPageContainer>
        <Container>
          <Spacer width={0} height={17} />
          <TopContainer>
            <ProfileContainer>
              <StarProfileImage
                src={profileImgUrl != "" ? profileImgUrl : no_profile_pic}
              />
            </ProfileContainer>
            <Spacer height={10} />
            {starSetInfo && <Name>{starSetInfo.owner.display_name}</Name>}
            <Spacer height={20} />
            <TitleContainer>
              <Title>{textJson.titleUpper}</Title>
              <Title>
                {textContents.formatString(
                  textJson.titleLower,
                  <b>{textJson.titleFocus}</b>
                )}
              </Title>
            </TitleContainer>
            <Spacer height={15} />
            <DescriptionText>{textJson.slideText} </DescriptionText>
          </TopContainer>
          <BottomContainer>
            <GiveStarText>
              {Number.isInteger(stars) ? stars + ".0" : stars}
            </GiveStarText>
            <StarSliderContainer>
              <StyledSlider
                onChange={(value) => {
                  setStars(value);
                }}
                min={0}
                max={5}
                step={0.5}
                defaultValue={stars}
                railStyle={styles.sliderRail}
                trackStyle={[styles.sliderRail]}
                handleStyle={styles.handleStyle}
                value={stars}
              />
              <StyledStarRatings
                rating={stars}
                starSize={height * 0.06}
                name="rating"
              />
            </StarSliderContainer>
            <CommentContainer
              placeholder={textJson.commentText}
              value={comment}
              onChange={onCommentChange}
              maxlength={50}
            />
            <NoticeText>
              {textContents.formatString(
                textJson.noticeText,
                <b>{textJson.anonymousText}</b>
              )}
            </NoticeText>
            {sendStarSuccess ? (
              <Redirect
                to={{
                  pathname: "/" + starsetEncodedId + "/result",
                  state: {
                    starsNumber: stars,
                    starSetInfo: starSetInfo,
                    starResponse: starResponse,
                    profileImgUrl: profileImgUrl,
                  },
                }}
              />
            ) : null}
            <AnonymousGiveButton
              onClick={() => {
                onClickAnonymousButton();
              }}
            >
              <LogoImg src={logo} />

              <GiveButtonText>별점 주기</GiveButtonText>
            </AnonymousGiveButton>
          </BottomContainer>
        </Container>
      </StarPageContainer>
    );
  } else {
    return <ErrorPage code={404} />;
  }
}

const StarPageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${colors.greyColor};
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90%;
`;

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const AnonymousGiveButton = styled(StyledButton)`
  align-self: flex-end;
  width: 100%;
  height: ${height * 0.06}px;
  font-size: 12px;
  font-weight: 800;
  background-color: ${colors.themeColor};
  color: ${colors.greyColor};
  justify-content: space-between;
`;

const ProfileContainer = styled.div``;
const StarProfileImage = styled(ProfileImage)`
  height: ${height * 0.13}px;
  width: ${height * 0.13}px;
`;

const LogoImg = styled.img`
  height: 60%;
  resize-mode: contain;
  margin: auto 15px;
`;

const GiveButtonText = styled.p`
  margin: auto 10px;
  font-size: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  color: ${colors.themeColor};
  font-weight: 100;
  font-size: 32px;
  line-height: 40px;
`;

const Name = styled.div`
  color: ${colors.themeColor};
  font-weight: 200;
  font-size: 20px;
`;

const DescriptionText = styled.div`
  color: ${colors.themeColor};
  font-size: 14px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const GiveStarText = styled.h2`
  font-size: 35px;
  font-weight: 200;
  margin: 0;
  color: ${colors.themeColor};
  align-self: center;
`;

const styles = {
  sliderRail: {
    width: "100%",
    height: 100,
    backgroundColor: "rgba(52, 52, 52, 0)",
  },
  handleStyle: {
    height: 100,
    width: 50,
    marginBottom: 100,
    backgroundColor: "rgba(52, 52, 52, 0)",
    borderRadius: 0,
    borderColor: "rgba(52, 52, 52, 0)",
    boxShadow: 0,
    "&:active": {
      borderColor: "rgba(52, 52, 52, 0)",
      outlineStyle: "none",
    },
    ":active": {
      borderColor: "rgba(52, 52, 52, 0)",
      outlineStyle: "none",
    },
    "&:focus": {
      borderColor: "rgba(52, 52, 52, 0)",
      outlineStyle: "none",
    },
    ":focus": {
      borderColor: "rgba(52, 52, 52, 0)",
      outlineStyle: "none",
    },
    outlineStyle: "none",
  },
};

const StarSliderContainer = styled.div`
  margin-top: 0;
  align-self: center;
`;

const StyledSlider = styled(Slider)`
  width: 80%;
  z-index: 1;
  margin: 0 auto;
`;

const CommentContainer = styled.textarea`
  margin: 5px auto;
  background-color: transparent;
  width: 90%;
  height: ${height * 0.075}px;
  min-height: 40px;
  resize: none;
  border-radius: 20px;
  border-color: ${colors.themeColor};
  color: #a09874;
  outline: none;
  padding: 20px;
  z-index: 1;
`;

const FacebookText = styled.p`
  margin: auto 10px;
`;

const NoticeText = styled.p`
  font-size: 10px;
  text-align: right;
  align-self: flex-end;
  color: ${colors.themeColor};
  white-space: pre-wrap;
`;

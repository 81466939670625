import axios from "axios";
import getServerAddress from "./getServerAddress";

export { UserAPI } from "./user";
export { StarAPI } from "./star";

const server_address = getServerAddress();

export function ping() {
  axios
    .get(server_address + "/", {})
    .then(function (response) {
      if (response.status === 200) {
        console.log("server is live");
        return true;
      }
    })
    .catch(function (error) {
      console.log(error);
      console.log("server is dead");
      return false;
    });
}

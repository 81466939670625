import styled from "styled-components";
import { textContents } from "../assets/textContents";

export default function LoginNotice() {
  const textJson = textContents.loginNotice;

  return (
    <Notice>
      {textContents.formatString(
        textJson.whenText,
        <TermLink
          target="_blank"
          rel="noopener noreferrer"
          href={
            "https://www.notion.so/dodo4114/Terms-of-Service-63c217a0a8c44d4199b40cb18f3a1c8b"
          }
        >
          {textJson.termText}
        </TermLink>
      )}
    </Notice>
  );
}

const Notice = styled.p`
  color: #9d9d9d;
  font-size: 10px;
  white-space: pre-wrap;
  align-self: center;
  margin: 0;
`;

const TermLink = styled.a`
  color: white;
`;

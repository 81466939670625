import React, { Component } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";
import colors from "../assets/colors.json";

export default function LoadingSpinner() {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <ClipLoader
      color={colors.filledStarColor}
      css={style}
      loading={promiseInProgress}
      size={30}
    />
  );
}

const style = `
  position: absolute;
  top: 300px;
  align-self: center;
  margin:auto;

`;

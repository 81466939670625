import React, { useState, useEffect } from "react";
import styled from "styled-components";

export function LoadingPage() {
  return (
    <Container>
      <p>Loading...</p>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
`;

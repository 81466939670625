import { createSlice } from "@reduxjs/toolkit";

const initialStateJson = {
  firebaseUser: null,
  idToken: null,
  starMeUser: null,
  facebookAccessToken: null,
  isKor: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialStateJson,
  reducers: {
    logIn: (state, action) => {
      state.firebaseUser = action.payload.firebaseUser;
      state.idToken = action.payload.idToken;
    },
    updateStarMeUser: (state, action) => {
      state.starMeUser = action.payload.starMeUser;
    },
    setFacebookAccessToken: (state, action) => {
      state.facebookAccessToken = action.payload.facebookAccessToken;
    },
    logOut: (state) => {
      state.firebaseUser = null;
      state.starMeUser = null;
      state.idToken = null;
      state.facebookAccessToken = null;
    },
    setIsKor: (state, action) => {
      // state.isKor = action.payload;
    },
  },
});

export const {
  logIn,
  logOut,
  updateStarMeUser,
  setFacebookAccessToken,
  setIsKor,
} = userSlice.actions;

export const getFirebaseUser = (state) => state.user.firebaseUser;
export const getIdToken = (state) => state.user.idToken;
export const getStarMeUser = (state) => state.user.starMeUser;
export const getIsKor = (state) => state.user.isKor;

export default userSlice.reducer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import { ProfileImage } from "../components/styled";
import { useLocation } from "react-router-dom";
import * as api from "../api/_index";

import "firebase/analytics";
import {
  getFirebaseUser,
  getStarMeUser,
  getIdToken,
  updateStarMeUser,
  logIn,
  logOut,
  selectUser,
  getIsKor,
} from "../features/user/userSlice";
import { textContents } from "../assets/textContents";
import StyledStarRatings from "../components/StyledStarRatings";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { FaLink } from "react-icons/fa";

import colors from "../assets/colors.json";
import KakaoShareButton from "../components/KakaoShareBtn";
import getUserLocale from "get-user-locale";

import { StyledButton } from "../components/styled";
import { LogScreen } from "../services/analytics";

export default function ShareBunddle(props) {
  const receiverName = props.receiverName;
  const encodedId = props.encodedId;

  const logScreen = new LogScreen(props.pageName);
  const host =
    process.env.NODE_ENV == "development"
      ? "http://localhost:3000"
      : "https://starme.site";
  const shareUrl = host + "/" + encodedId + "/";

  const textJson = textContents.myPage;

  const alert = useAlert();
  const isKor = useSelector(getIsKor);
  function shareBtnClicked() {
    logScreen.log("copy_link_share");
    const postTitle = "STAR ME";
    const siteTitle = "STARME";
    if (navigator.share) {
      navigator
        .share({
          title: `${postTitle}`,
          text: textContents.formatString(textJson.shareButtonText, {
            name: receiverName,
          }),
          url: `\n\n${shareUrl}`,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong on sharing", error);
        });
    } else {
      alert.show(textJson.copiedText);
    }
  }

  return (
    <ShareButtonsContainer>
      <StyledFacebookShareButton
        beforeOnClick={() => {
          logScreen.log("facebook_share");
        }}
        quote={"Star Me"}
        url={shareUrl}
        href={shareUrl}
      >
        <StyledFacebookIcon round={true} />
      </StyledFacebookShareButton>

      <StyledWhatsappShareButton
        quote={"Star Me"}
        url={shareUrl}
        beforeOnClick={() => {
          logScreen.log("whatsapp_share");
        }}
      >
        <StyledWhatsappIcon round={true} />
      </StyledWhatsappShareButton>
      {isKor ? (
        <KakaoShareButton
          json={{
            title: "별점을 주세요",
            subTitle: receiverName,
            encodedId: encodedId,
          }}
          onClick={() => {
            logScreen.log("kakao_share");
          }}
        ></KakaoShareButton>
      ) : null}

      <CopyToClipboard text={shareUrl} onCopy={() => shareBtnClicked()}>
        <CopyBtn>
          <FaLink size={23} />
        </CopyBtn>
      </CopyToClipboard>
    </ShareButtonsContainer>
  );
}

const CopyBtn = styled(StyledButton)`
  border-radius: 100px;
  margin: 0 3px;
  width: 35px;
  height: 35px;
  color: black;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: lightgray;
  &:hover {
    cursor: pointer;
  }
`;

const ShareButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
`;

const StyledFacebookShareButton = styled(FacebookShareButton)`
  width: 35px;
  height: 100%;
  margin: 0 3px;
`;
const StyledFacebookIcon = styled(FacebookIcon)`
  width: 35px;
  height: 100%;
`;
const StyledWhatsappShareButton = styled(WhatsappShareButton)`
  margin: 0 3px;
  width: 35px;
  height: 100%;
`;
const StyledWhatsappIcon = styled(WhatsappIcon)`
  width: 35px;
  height: 100%;
`;

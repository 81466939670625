import LocalizedStrings from "react-localization";

export const textContents = new LocalizedStrings({
  en: {
    introPage: {
      title: "StarMe",
      bigSubtitle: "Wanna know yourself?",
      subtitle: "Get stars & comments from friends.",
      facebookText: "Start with Facebook",
    },
    myPage: {
      welcomeText: "Welcome,",
      welcomeName: "!",
      peopleText: "Now {0} friends gave you stars,",
      notEnoughText: "You need {0} more friends to know your rate!",
      starText: "you got an average of {0} stars",
      commentTab: "Comments",
      shareTab: "Share",
      shareText: "Share your link, and get more stars!",
      copyText: "Copy Link",
      copiedText: "Link Copied",
      detailButton: "Detail",
      sloganText: "How many {howManyStar}\n do I deserve?",
      shareButtonText: "How many STARs does {name} deserve? Star ME!",
      detailButtonText: "See comments",
      friendPageButton: "Go to See Friends' stars",
    },
    myStarDetailPage: {
      title: "Comments",
      peopleText: "Now {0} friends gave you star,",
      starText: "you got an average of {0} stars from your friends.",
      noCommentText: "No Comment",
      notEnoughText: "You need {0} more star(s) to know your star-rate",
    },
    friendDetailPage: {
      peopleText: "Now {0} friends gave you star,",
      starText: "you got an average of {0} stars from your friends.",
      noCommentText: "No Comment",
      notEnoughText: "You need {0} more star(s) to know your star-rate",
      starScreenButton: "Go to star",
      friendPageButton: "Go back to friends list",
      shareText: "Share {name}'s link to friends",
    },
    starPage: {
      titleUpper: "How Many",
      titleLower: "Stars Do I Deserve?",
      slideText: "Slide Stars!",
      commentText: "Comment here",
      giveText: "Give Stars",
      starText: "give {0} stars",
      howManyStar: "STARs",
      noticeText:
        "To prevent duplication, facebook account is needed.\nStar data will be passed as {0}",
      anonymousText: "ANONYMOUS",
    },
    friendsPage: {
      title: "Friend List",
    },
    resultPage: {
      title: "You stared successfully",
      subTitle: "To {name}, {starsNumber} stars are given successfully",
      suggestText: "How many stars I deserve?",
      facebookText: "Get STARs with Facebook",
      loggedFacebookText: "Get STARs as {name}",
      recommendText: "{name} is waiting for your stars!",
      averageText:
        "{name} got an average of {average} stars from {starsNumber}",
      notEnoughText:
        "To calculate average stars, at least 5 friends' stars are needed",
      goMypageText: "Create My Star Link",
      myPageSuggest: "Do you wanna know\nyour star-rate and comments?",
      suggestLoginText:
        "After login, you can see {name}'s star-rate and commments!",
    },
    loginNotice: {
      whenText: "By continuing, you agree to our {0}.",
      termText: "terms of service ",
    },
    general: {
      logout: "LOGOUT",
      instaText:
        "If using Instgram,\nplease clik upper right ···\nand open via other browser.",
    },

    sorryText: {
      instaTitle: "in Instagram,\nFacebook login not working😢",
      instaEscape: "Escape Instagram",
      kakaoTitle: "in KakaoTalk\nFacebook login not working😢",
      kakaoEscape: "Escape KakaoTalk",
      subTitle: "We stay up all night to Fix...😂",
    },
  },
  ko: {
    introPage: {
      title: "StarMe",
      bigSubtitle: "나의 평판이 궁금하다면?",
      subtitle: "친구들한테 별점과 코멘트를 받아보세요!",
      howManyStar: "몇 별",
      facebookText: "페이스북 로그인",
    },
    myPage: {
      welcomeText: "반갑습니다,",
      welcomeName: `님!`,
      peopleText: "현재 {0}명의 친구가 참여했으며,",
      notEnoughText: "별점이 계산되려면 {0}명이 더 필요해요!",
      starText: "평균 {0}개의 별점을 받으셨습니다.",
      commentTab: "내가 받은 코멘트",
      shareTab: "링크 공유하기",
      shareText: "링크를 공유하고 별점을 더 받아보세요!",
      copyText: "링크 공유",
      detailButton: "자세히 보기",
      copiedText: "링크가 복사되었습니다.",
      sloganText: "나는 친구들에게\n{howManyStar}짜리 사람인가요?",
      shareButtonText:
        "{name}은 어떤 사람인가요? 익명으로 별점을 매겨주세요.\n",
      detailButtonText: "코멘트 보기",
      friendPageButton: "친구들 별점 보기",
    },
    myStarDetailPage: {
      title: "내가 받은 코멘트",
      peopleText: "현재 {0}명의 사람들이 참여했으며,",
      starText: "평균 {0}개의 별점을 받으셨습니다.",
      noCommentText: "코멘트가 없습니다.",
      notEnoughText: "별점이 계산되려면 {0}명의 참여가 더 필요합니다.",
    },
    friendDetailPage: {
      peopleText: "{0}명의 사람들이 참여했으며,",
      starText: "평균 {0}개의 별을 받았어요.",
      noCommentText: "코멘트가 없습니다.",
      notEnoughText: "별점이 계산되려면 {0}명의 참여가 더 필요합니다.",
      starScreenButton: "별 주러 가기",
      friendPageButton: "친구 목록으로 돌아가기",
      shareText: "{name}님의 링크를 공유해보세요",
    },
    starPage: {
      titleUpper: "나는 당신에게",
      titleLower: "{0}짜리 사람인가요?",
      titleFocus: "몇 별",
      slideText: "별들을 좌우로 슬라이드 해보세요!",
      commentText: "별점을 주신 이유가 있나요?",
      facebookText: "페이스북으로 별점주기",
      starText: "별 {0}개 주기",
      howManyStar: "몇 별",
      noticeText: "별점 정보는 {0}됩니다.",
      anonymousText: "익명으로 전달",
    },
    friendsPage: {
      title: "내 친구 목록",
    },
    resultPage: {
      title: "전달 완료!",
      subTitle: "{name}님에게 {starsNumber}개의 별이 성공적으로 전달되었어요.",
      averageText:
        "{name}님은 {starsNumber}명에게 평균 {average}개의 별을 받았습니다.",
      notEnoughText: "5명이 {name}님에게 별점을 줘야 별점이 공개됩니다.",
      suggestLoginText: "로그인하시면 {name}의 별점 결과를 확인하실 수 있어요!",
      suggestText: "다른 친구를 찾아\n별점을 줄 수도 있어요😀",
      facebookText: "페이스북 로그인",
      loggedFacebookText: "내 별점 알아보기",
      recommendText: "{name}님도 별점을 기다리고 있어요!",
      myPageSuggest: "내 별점과 코멘트가 궁금하다면?",
      goMypageText: "별점 링크 만들기",
      blockedText: "{name}님은 몇 개의 별과 코멘트를 받았을까요?",
      blockedLoginText: "아래에서 간편하게 로그인하고 확인해보세요!",
    },
    loginNotice: {
      whenText: "위 버튼을 누르시면 {0}에 동의하는 것으로 간주됩니다.",
      termText: "이용약관",
    },
    general: {
      logout: "로그아웃",
      instaText:
        "인스타그램을 통해 접속하셨다면,\n오른쪽 위의 ··· 버튼을 눌러\n다른 브라우저에서 열어주세요.",
    },
    sorryText: {
      instaTitle: "인스타에서는\n페북 로그인이 잘 안돼요😢",
      instaEscape: "인스타그램 탈출",
      kakaoTitle: "카카오에서는\n페북 로그인이 잘 안돼요😢",
      kakaoEscape: "카카오톡 탈출",
      subTitle: "밤 새며 고치는 중...😂",
    },
  },
});

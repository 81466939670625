import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Spacer, StyledLink } from "./styled";

import { getFBImgUrl } from "../services/FacebookSdk";
import no_profile_pic from "../assets/no-profile-pic.jpg";
import colors from "../assets/colors.json";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

export default function Friend(props) {
  const data = props.data;
  const name = data.display_name;
  const facebook_uid = data.facebook_uid;
  const starsetId = data.starsets[0] ? data.starsets[0].encoded_id : "";

  const [profileImgUrl, setProfileImgUrl] = useState(data.profile_image);
  useEffect(() => {
    if (profileImgUrl == "" && facebook_uid != "") {
      getFBImgUrl(facebook_uid).then(setProfileImgUrl);
    }
  }, [profileImgUrl]);

  return (
    <FriendLink
      className={props.className}
      to={"/" + starsetId + (props.goStar ? "/" : "/detail/")}
    >
      <FriendProfileImg
        src={profileImgUrl != "" ? profileImgUrl : no_profile_pic}
      />
      {props.hideName ? null : (
        <>
          <Spacer width={0} height={10} />
          <FriendName>{name}</FriendName>
          <Spacer width={0} height={10} />
        </>
      )}
    </FriendLink>
  );
}

const FriendLink = styled(StyledLink)`
  display: flex;
  flex-direction: column;
`;

const FriendName = styled.div`
  color: ${colors.themeColor};
  font-size: 12px;
`;

const FriendProfileImg = styled.img`
  resize-mode: contain;
  width: ${width * 0.16}px;
  height: ${width * 0.16}px;
  margin: 0;
  padding: 0;
  border-radius: 100px;
`;

import React, { useState, useEffect } from "react";
import logo from "../assets/logo/Starme_logo@1x.svg";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/auth";

import "firebase/analytics";
import * as api from "../api/_index";
import { useSelector, useDispatch } from "react-redux";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import StarRatings from "react-star-ratings";
import { ErrorPage } from "./ErrorPage";
import { FaFacebook } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import LoginButton from "../components/LoginButton";
import colors from "../assets/colors.json";

import {
  getFirebaseUser,
  getStarMeUser,
  getIdToken,
  logIn,
  logOut,
  selectUser,
  updateStarMeUser,
} from "../features/user/userSlice";
import { checkLoginState, facebookLogin } from "../services/FacebookSdk";
import { textContents } from "../assets/textContents";
import {
  Container,
  StyledButton,
  ProfileImage,
  Spacer,
  StyledLink,
} from "../components/styled";
import StyledStarRatings from "../components/StyledStarRatings";
import Friend from "../components/Friend";
import no_profile_pic from "../assets/no-profile-pic.jpg";
import ShareBunddle from "../components/ShareBunddle";
import FacebookButton from "../components/FacebookButton";
import KakaoButton from "../components/KakaoButton";
import { LogScreen } from "../services/analytics";

import { trackPromise } from "react-promise-tracker";

const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

const isMobile = window.innerWidth <= 700;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function StarResultPage(props) {
  const logScreen = new LogScreen("StarResultPage");

  useEffect(() => {
    logScreen.screenLog();
  }, []);

  const textJson = textContents.resultPage;
  const firebaseUser = useSelector(getFirebaseUser);

  const idToken = useSelector(getIdToken);
  const starMeUser = useSelector(getStarMeUser);
  const dispatch = useDispatch();

  const isLogin = Boolean(firebaseUser);

  const starsetEncodedId = props.match.params.encodedId;
  const [isLoading, setIsLoading] = useState(true);

  let query = useQuery();
  const resultInfo = props.location.state ? props.location.state : null;

  const stars = resultInfo ? resultInfo.starsNumber : query.get("stars");
  const starResponse = resultInfo ? resultInfo.starResponse : null;
  const starSetInfo = resultInfo ? resultInfo.starSetInfo : null;

  const [profileImgUrl, setProfileImgUrl] = useState(
    resultInfo ? resultInfo.profileImgUrl : ""
  );

  const [receiverName, setReceiverName] = useState(
    starSetInfo ? starSetInfo.owner.display_name : ""
  );

  const recommendedProfile = starResponse
    ? starResponse.recommend_profile
    : null;

  const starId = starResponse ? starResponse.id : query.get("starId");

  const [passedState, setPassedStaste] = useState({
    stars: stars,
    starId: starId,
  });

  const searchParams = "?" + new URLSearchParams(passedState).toString();

  const [starset, setStarset] = useState(
    starResponse ? starResponse.starset : null
  );

  const [starsetData, setStarsetData] = useState([]);

  const average = starset ? starset.average : 0;
  const isEnough = average != "Not enough stars";

  const [isExist, setIsExist] = useState(true);

  useEffect(() => {
    getStarSetInfo(starsetEncodedId).then((data) => {
      setStarset(data);
      const owner = data.owner;
      setReceiverName(owner.display_name);
      setProfileImgUrl(owner.profile_image);
    });
  }, [firebaseUser]);

  useEffect(() => {
    trackPromise(
      getStarSetInfo(starsetEncodedId)
        .then((data) => {
          if (data.stars) {
            console.log(data);
            setStarsetData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );
  }, [starsetEncodedId]);

  useEffect(() => {
    if (firebaseUser && starId) {
      const requester = new api.StarAPI(idToken);
      requester
        .set_star_as_mine(starId)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [firebaseUser]);

  function StarSet(props) {
    const data = props.data;
    const title = data.title;
    const starListData = data.stars;
    const starItems = !starListData
      ? []
      : starListData
          .sort((a, b) => {
            if (a.value > b.value) {
              return -1;
            } else if (a.value < b.value) {
              return 1;
            } else {
              if (a.comment) {
                return -1;
              }
              return 0;
            }
          })
          .slice(0, 4)
          .map((star, index) => (
            <StarSetContainer key={index}>
              <Spacer width={15} height={0} />
              <StyledStarRatings
                rating={star.value}
                starSize={10}
                emptyStarColor={"transparent"}
              />
              <CommentContainer>
                <CommentText isEmpty={!Boolean(star.comment)}>
                  {star.comment ? star.comment : textJson.noCommentText}
                </CommentText>
              </CommentContainer>
            </StarSetContainer>
          ));

    return <StarList>{starItems}</StarList>;
  }

  function getStarSetInfo(encodedId) {
    const requester = new api.StarAPI(idToken);
    return new Promise((resolve, reject) => {
      requester
        .get_starset(encodedId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 404") {
            setIsExist(false);
          }
          reject();
        });
    });
  }

  function onFacebookButton() {
    logScreen.log("login", { login_with: "facebook" });
    facebookLogin();
  }

  const [fbuser, setUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  if (isExist) {
    return (
      <StarResultPageContainer>
        {isMobile ? (
          <Spacer height={height * 0.1} />
        ) : (
          <Spacer height={height * 0.05} />
        )}
        <InnerContainer>
          <Title>{textJson.title}</Title>

          <Spacer height={5} />
          <SubTitle>
            {textContents.formatString(textJson.subTitle, {
              name: receiverName,
              starsNumber: stars,
            })}
          </SubTitle>
          {/* {!isLogin ? (
            <SubTitle>
              {textContents.formatString(textJson.suggestLoginText, {
                name: receiverName,
              })}
            </SubTitle>
          ) : isEnough ? (
            <>
              <SubTitle>
                {textContents.formatString(textJson.averageText, {
                  name: receiverName,
                  starsNumber: starset ? starset.num_of_valid_stars : 0,
                  average: average,
                })}
              </SubTitle>
            </>
          ) : (
            <SubTitle>
              {textContents.formatString(textJson.notEnoughText, {
                name: receiverName,
              })}
            </SubTitle>
          )} */}
          <Spacer height={20} />
          {isEnough && starset ? (
            <FriendStarResultContainer>
              {!isLogin && (
                <BlockedContainer>
                  <BlockedEmoji>🤔</BlockedEmoji>
                  <BlockedText>
                    {textContents.formatString(textJson.blockedText, {
                      name: receiverName,
                    })}
                  </BlockedText>
                  <Spacer height={5} />
                  <BlockedText>{textJson.blockedLoginText}</BlockedText>
                </BlockedContainer>
              )}
              <FriendStarInfo isLogin={isLogin}>
                <FriendInfo>
                  <FriendInfoLeft>
                    <Spacer height={height * 0.03} />
                    <FriendProfileImage
                      src={profileImgUrl ? profileImgUrl : no_profile_pic}
                    />
                    <Spacer height={10} />
                    <FriendName>{receiverName}</FriendName>
                  </FriendInfoLeft>
                  <FriendInfoRight>
                    <Spacer height={height * 0.03} />
                    <Rating>{isLogin ? average : "0.00"}</Rating>
                    <Spacer height={5} />
                    <StyledStarRatings
                      rating={isEnough ? average : 0}
                      isEnough={isLogin && isEnough}
                      starSize={height * 0.045}
                    />
                  </FriendInfoRight>
                </FriendInfo>
                <StarSet data={starsetData} />
                <StyledLink to={"/" + starsetEncodedId + "/detail"}>
                  <FriendStarDetail>자세히 보러가기</FriendStarDetail>
                </StyledLink>
              </FriendStarInfo>
            </FriendStarResultContainer>
          ) : null}
          <Spacer height={10} />
          <ButtonsConatiner>
            {isLogin ? (
              <ButtonAndTextContainer>
                {textJson.myPageSuggest}
                <Spacer height={10} />
                <StyledLink to={"/"}>
                  <MainPageButton
                    onClick={() => {
                      logScreen.log("goToMyPage");
                    }}
                  >
                    {textJson.goMypageText}
                  </MainPageButton>
                </StyledLink>
              </ButtonAndTextContainer>
            ) : (
              <ButtonAndTextContainer>
                {textJson.suggestText}
                <Spacer height={10} />
                <KakaoButton
                  state={passedState}
                  onClick={() => {
                    logScreen.log("login", { login_with: "kakao" });
                  }}
                />
                <Spacer width={0} height={10} />
                <FacebookButton
                  onClick={() => onFacebookButton()}
                  text={textJson.facebookText}
                  searchParams={searchParams}
                />
              </ButtonAndTextContainer>
            )}
            <Spacer height={20} />
            {recommendedProfile ? (
              <RecommendLink
                to={
                  "/" +
                  (recommendedProfile.starsets[0]
                    ? recommendedProfile.starsets[0].encoded_id + "/"
                    : "")
                }
              >
                <RecommendFriend
                  goStar
                  hideName
                  data={recommendedProfile}
                  onClick={() => {
                    logScreen.log("clickRecommendFriend");
                  }}
                />
                <Spacer width={10} />
                <RecommendText>
                  {textContents.formatString(textJson.recommendText, {
                    name: recommendedProfile.display_name,
                  })}
                </RecommendText>
              </RecommendLink>
            ) : null}
          </ButtonsConatiner>
        </InnerContainer>
      </StarResultPageContainer>
    );
  } else {
    return <ErrorPage code={404} />;
  }
}

const StarResultPageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: ${colors.themeColor};
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  width: 90%;
`;

const Title = styled.h1`
  color: ${colors.greyColor};
  font-weight: 600;
  font-size: 30px;
  margin: 0px;
`;

const ButtonAndTextContainer = styled.div`
  color: ${colors.greyColor};
  font-size: 13px;
  width: ${width * 0.8}px;
`;

const FriendStarResultContainer = styled(Container)`
  margin: 0;
`;

const FriendStarInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${colors.greyColor};
  filter: ${(props) => (props.isLogin ? "none" : "blur(4px)")};
`;

const BlockedContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-self: center;
  top: ${height * 0.35}px;
  z-index: 1;
  filter: none;
  color: ${colors.themeColor};
  font-size: 12px;
`;

const BlockedEmoji = styled.p`
  font-size: 20px;
`;

const BlockedText = styled.div``;

const FriendProfileImage = styled(ProfileImage)`
  height: ${height * 0.1}px;
  width: ${height * 0.1}px;
  resize-mode: contain;
  margin: 0;
`;

const FriendInfo = styled.div`
  display: flex;
  flex-direction: row;
  height: ${height * 0.2}px;
`;

const FriendInfoLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`;

const FriendName = styled.div`
  font-size: 12px;
  color: ${colors.themeColor};
`;

const FriendInfoRight = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 15px;
`;

const FriendStarDetail = styled.button`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: transparent;
  width: 100%;
  background-color: ${colors.themeColor};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 5px;
  outline: none;
  color: ${colors.greyColor};
`;

const SubTitle = styled.h2`
  color: ${colors.greyColor};
  font-weight: 400;
  font-size: 12px;
  white-space: pre-line;
  margin: 2px;
`;

const RecommendLink = styled(StyledLink)`
  margin: 0;
  padding: 0;
  flex-direction: row;
  justify-content: center;
`;
const GiverImg = styled.img`
  height: 100%;
  border-radius: 10px 0 0 10px;
  border-width: 0;
  margin: 0;
`;

const MiddleContainer = styled(Container)`
  height: 25%;
  overflow: hidden;
`;

const ButtonsConatiner = styled.div`
  align-self: center;
`;

const RecommendFriend = styled(Friend)`
  margin: 0;
  padding: 0;
  flex: 0;
`;
const RecommendText = styled.p`
  color: black;
  font-size: 12px;
  margin: 0;
`;

const StarListContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StarList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  height: 100%;
  overflow: auto;
`;

const Rating = styled.div`
  color: ${colors.themeColor};
  font-weight: 200;
  font-size: 36px;
  margin: 0px;
`;

const StarSetContainer = styled.li`
  display: flex;
  width: 97%;
  align-items: center;
  flex: 1;
  height: 30%;
`;

const CommentContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
`;

const CommentText = styled.div`
  margin: auto 20px;
  width: 100%;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
  word-break: break-all;
  color: ${(props) => (props.isEmpty ? "#9D9D9D" : "white")};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: right;
`;

const MainPageButton = styled(StyledButton)`
  width: ${width * 0.55}px;
  height: ${height * 0.06}px;
  font-size: 14px;
`;

const StarSetListItem = styled.li``;

import React, { useState, useEffect } from "react";
import logo from "../assets/logo/Starme_logo@1x.svg";
import styled from "styled-components";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { getFirebaseUser, getStarMeUser } from "../features/user/userSlice";

import { textContents } from "../assets/textContents";
import { facebookLogin } from "../services/FacebookSdk";
import {
  Container,
  Spacer,
  Highlited,
  StyledButton,
} from "../components/styled";

import FacebookButton from "../components/FacebookButton";

import { trackPromise } from "react-promise-tracker";
import { useAlert } from "react-alert";
import KakaoButton from "../components/KakaoButton";
import { LogScreen } from "../services/analytics";

import LoginNotice from "../components/loginNotice";
const width = Math.min(400, window.innerWidth);
const height = window.innerHeight;

export function IntroPage(props) {
  const textJson = textContents.introPage;
  const dispatch = useDispatch();
  const alert = useAlert();
  const [response, setResponse] = useState("");

  const logScreen = new LogScreen("IntroPage");

  useEffect(() => {
    logScreen.screenLog();
  }, []);

  const firebaseUser = useSelector(getFirebaseUser);
  const starMeUser = useSelector(getStarMeUser);

  function onClickFacebookButton() {
    logScreen.log("login", { login_with: "facebook" });
    trackPromise(
      facebookLogin().then(({ response }) => {
        setResponse(JSON.stringify(response));
      })
    );
  }

  return (
    <Container>
      <TopContainer>
        <Spacer width={0} height={height * 0.1} />
        <LogoImg src={logo} />
      </TopContainer>
      <BottomContainer>
        <Spacer width={width * 0.08} height={0} />
        <BottomContentContainer>
          <Spacer width={0} height={height * 0.03} />
          <Title>{textJson.title}</Title>
          <Spacer width={0} height={height * 0.03} />
          <BigSubTitle>{textJson.bigSubtitle}</BigSubTitle>
          <Spacer width={0} height={height * 0.01} />
          <SubTitle>{textJson.subtitle}</SubTitle>
          <Spacer width={0} height={height * 0.05} />
          <KakaoButton
            onClick={() => {
              logScreen.log("login", { login_with: "kakao" });
            }}
          />
          <Spacer width={0} height={10} />
          <FacebookButton
            onClick={() => onClickFacebookButton()}
            text={textJson.facebookText}
          />
          <Spacer width={0} height={height * 0.02} />
          <LoginNotice show />
        </BottomContentContainer>
        <Spacer width={width * 0.08} height={0} />
      </BottomContainer>
    </Container>
  );
}

const LogoImg = styled.img`
  height: ${height * 0.18}px;
  resize-mode: contain;
`;

const TopContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const BottomContainer = styled(TopContainer)`
  display: flex;
  flex-direction: row;
  background-color: #3a3a3a;
`;

const BottomContentContainer = styled(TopContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Title = styled.h1`
  margin: 0;
  font-size: ${0.1 * width}px;
  font-weight: 100;
  color: #ffda3e;
`;

const SubTitle = styled.h2`
  margin: 0;
  white-space: pre-line;
  font-size: ${0.03 * width}px;
  font-weight: 300;
  color: #ffffff;
`;

const BigSubTitle = styled(SubTitle)`
  font-size: ${0.035 * width}px;
`;

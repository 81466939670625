import axios from "axios";
import qs from "querystring";
import getServerAddress from "./getServerAddress";

const server_address = getServerAddress();
const api_address = server_address + "/api/v1";

export class StarAPI {
  constructor(FirebaseIDToken = null) {
    this.userAxios = axios.create({
      baseURL: api_address + "/starset",
      headers: {
        Authorization: FirebaseIDToken
          ? "FirebaseIDToken " + FirebaseIDToken
          : "",
      },
      timeout: 5000,
      transformRequest: [(data) => qs.stringify(data)],
    });
  }

  get_starset(encodedId) {
    return this.userAxios
      .get(`/${encodedId}/`, {})
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
  }
  give_star_to_starset(encodedId, stars, comment) {
    return this.userAxios
      .post(`/${encodedId}/star/`, {
        value: stars,
        comment: comment,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  set_star_as_mine(id) {
    return this.userAxios
      .put(`/star/` + id + "/", {})
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
}
